import {
  FieldSize, InputField, InputType, TextArea,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import { PageSubtitle, PageTitle } from '../../components/page/PageElements'
import { useBasket } from '../../context/BasketProvider'
import { getFieldError, getFieldMessage } from '../../helpers/validityHelper'
import {
  giftFirstNameTest,
  giftLastNameTest,
  giftMessageFromTest,
} from './helpers/personaliseTests'

export default function Personalisation({
  showErrors,
}: {
  showErrors: boolean
}): ReactElement {
  const { currentItem, currentRecipient, changeCurrentRecipient } = useBasket()

  const {
    voucherFirstName, voucherLastName, messageTo, message, messageFrom,
  } = currentRecipient

  return (
    <>
      <PageTitle>Personalise</PageTitle>
      <PageSubtitle>
        Who is the gift for? This name will appear on the gift voucher.
      </PageSubtitle>
      <InputField
        label="First name"
        type={InputType.Text}
        onChange={(value): void => changeCurrentRecipient({ voucherFirstName: value })}
        value={voucherFirstName || ''}
        isError={getFieldError(giftFirstNameTest, currentItem, showErrors)}
        subtitle={getFieldMessage(giftFirstNameTest, currentItem, showErrors)}
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      <InputField
        label="Last name"
        type={InputType.Text}
        onChange={(value): void => changeCurrentRecipient({ voucherLastName: value })}
        value={voucherLastName || ''}
        isError={getFieldError(giftLastNameTest, currentItem, showErrors)}
        subtitle={getFieldMessage(giftLastNameTest, currentItem, showErrors)}
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      <PageSubtitle>Add a personal message for the recipient.</PageSubtitle>
      <InputField
        label="To"
        type={InputType.Text}
        onChange={(value): void => changeCurrentRecipient({ messageTo: value })}
        value={messageTo || ''}
        placeholder={voucherFirstName && `To ${voucherFirstName}`}
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      <TextArea
        label="Message"
        onChange={(value): void => changeCurrentRecipient({ message: value })}
        value={message || ''}
        placeholder="I hope you enjoy this gift!"
        rows={4}
        fieldSize={FieldSize.Large}
        maxChars={100}
      />
      <InputField
        label="From"
        type={InputType.Text}
        onChange={(value): void => changeCurrentRecipient({ messageFrom: value })}
        value={messageFrom || ''}
        isError={getFieldError(giftMessageFromTest, currentItem, showErrors)}
        subtitle={getFieldMessage(giftMessageFromTest, currentItem, showErrors)}
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      <PageSubtitle>
        Please check your message carefully. Mistakes cannot be rectified later.
      </PageSubtitle>
    </>
  )
}
