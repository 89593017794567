import {
  DeliveryMethod,
  ItemQuestionResponses,
  RecipientType,
} from './itemTypes'

export enum Resource {
  Organisation = '/purchase/organisation',
  PlaceOrder = '/purchase/order',
  ValidateOrder = '/purchase/validate',
  TrustToken = '/purchase/trust',
  ManualOrderToken = '/authenticate/manual-token',
  VerifyTrust = '/purchase/verify',
  Preview = '/purchase/preview',
}

export interface IPreparedItem {
  itemId: number
  deliveryMethod: DeliveryMethod
  recipientType?: RecipientType
  quantity: number
  individuals: number
  voucherFirstName: string
  voucherLastName: string
  deliveryFirstName?: string
  deliveryLastName?: string
  recipientEmail?: string
  addressLineOne?: string
  addressLineTwo?: string
  town?: string
  postcode?: string
  messageTo: string
  message: string
  messageFrom: string
  dispatchDate?: number
  value: number
  questionResponses?: ItemQuestionResponses
}
export interface IOrderPostData {
  items: IPreparedItem[]
  purchaserEmail: string
  purchaserFirstName: string
  purchaserLastName: string
  purchaserPhone: string
  purchaserInternational: boolean
  purchaserAddressLineOne: string
  purchaserAddressLineTwo: string
  purchaserTown: string
  purchaserPostcode: string
  joinMailingList: boolean
  orderTotal: number
  organisationId: number
  paymentReference?: string
}

export type IPayPalOrderResponse = IGenericOrderResponse & {
  clientId: string
}

export type IStripeOrderResponse = IGenericOrderResponse & {
  billingDetails: object
}

export type IOrderResponse = IGenericOrderResponse | IPayPalOrderResponse

export interface IGenericOrderResponse {
  orderId: string
  paymentKey: string
  pdfUrl: string
  invoiceUrl: string
}

export interface IPreviewPostData {
  itemId: number
  quantity: number
  individuals: number
  voucherFirstName: string
  voucherLastName: string
  messageTo: string
  message: string
  messageFrom: string
  value: number
}
