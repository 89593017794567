export enum DeliveryMethod {
  Email = 'email',
  Post = 'post',
  Print = 'print',
}
export enum RecipientType {
  Purchaser = 'purchaser',
  Recipient = 'recipient',
}

export interface IQuestionResponse {
  questionId: number
  answer: string
  answered?: boolean
}
export type ItemQuestionResponses = IQuestionResponse[][]
