import { OrganisationFormat } from '@one-tree/library'
import EmailValidator from 'email-validator'
import isValid from 'uk-postcode-validator'
import { IBasketItem } from '../../../types/dataTypes'
import { DeliveryMethod, RecipientType } from '../../../types/itemTypes'

export const deliveryMethodTest = (
  basketItem: IBasketItem | undefined,
): string | false => {
  if (
    basketItem?.format === OrganisationFormat.GiftVouchers
    && !basketItem?.recipient.deliveryMethod
  ) {
    return 'Delivery method required'
  }
  return false
}

export const deliveryRecipientTest = (
  basketItem: IBasketItem | undefined,
): string | false => {
  if (
    basketItem?.format === OrganisationFormat.GiftVouchers
    && basketItem?.recipient.deliveryMethod !== DeliveryMethod.Print
    && !basketItem?.recipient.recipientType
  ) {
    return 'Delivery recipient required'
  }
  return false
}

export const recipientEmailTest = (
  basketItem: IBasketItem | undefined,
): string | false => {
  const deliverToRecipient = basketItem?.recipient.recipientType === RecipientType.Recipient
  const deliverByEmail = basketItem?.recipient.deliveryMethod === DeliveryMethod.Email

  if (deliverToRecipient && deliverByEmail) {
    const email = basketItem?.recipient?.email

    if (!email) {
      return 'Recipient email required'
    }
    if (email && !EmailValidator.validate(email)) {
      return 'Recipient email invalid'
    }
  }
  return false
}

export const recipientEmailConfirmTest = (
  basketItem: IBasketItem | undefined,
): string | false => {
  const deliverToRecipient = basketItem?.recipient.recipientType === RecipientType.Recipient
  const deliverByEmail = basketItem?.recipient.deliveryMethod === DeliveryMethod.Email

  if (deliverToRecipient && deliverByEmail) {
    const email = basketItem?.recipient?.email
    const emailConfirm = basketItem?.recipient?.emailConfirm

    if (email !== emailConfirm) {
      return 'Email confirmation does not match'
    }
  }
  return false
}

export const recipientAddressTest = (
  basketItem: IBasketItem | undefined,
): string | false => {
  const {
    recipientType, deliveryMethod, addressLineOne, town, postcode,
  } = basketItem?.recipient || {}

  const deliverToRecipient = recipientType === RecipientType.Recipient
  const deliverByPost = deliveryMethod === DeliveryMethod.Post
  const isRequired = deliverToRecipient && deliverByPost

  const addressIncomplete = !addressLineOne || !town || !postcode

  if (isRequired && addressIncomplete) {
    return 'Recipient address incomplete'
  }

  if (isRequired && (!postcode || !isValid(postcode))) {
    return 'UK postcode invalid'
  }

  return false
}
