import React, { ReactElement } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import styled from 'styled-components'
import { colors } from '@one-tree/library'
import { RoutePath } from '../../types/routes'
import PageNavigation from '../page/PageNavigation'
import { IPaymentProps } from '../../types/propTypes'
import { prepareOrder } from '../../helpers/basketHelper'
import { placeOrder } from '../../helpers/APIHelper'
import { PageText } from '../page/PageElements'
import { IStripeOrderResponse } from '../../types/APITypes'

const StripeContainer = styled.div`
  border: 1px solid ${colors.darkGray};
  padding: 10px;
  border-radius: 4px;
  &.has-error {
    border-color: ${colors.red};
    background-color: ${colors.lightestRed};
  }
`

export default function Stripe(props: IPaymentProps): ReactElement {
  const {
    basketItems,
    purchaser,
    errors,
    addError,
    clearErrors,
    organisation,
    saveOrder,
  } = props
  const stripe = useStripe()
  const elements = useElements()

  const isError = !stripe || !elements

  const handleOrder = async (): Promise<boolean> => {
    clearErrors()

    if (isError) {
      addError('Payment system not loaded')
      return false
    }

    const card = elements.getElement(CardElement)
    if (!card) {
      addError('Payment system issue')
      return false
    }

    if (!organisation) return false

    // Prepare the order data and submit to One Tree.
    const data = prepareOrder(basketItems, purchaser, organisation.id)
    if (!data) {
      addError('Error with order')
      return false
    }
    const res = await placeOrder<IStripeOrderResponse>(data)

    if (res.error) {
      addError(res.error)
      return false
    }
    if (!res.data) {
      addError('No response from server')
      return false
    }

    const { paymentKey, orderId, billingDetails } = res.data

    saveOrder(res.data)

    // Now take the payment using the returned paymentKey and orderId.
    const result = await stripe.confirmCardPayment(paymentKey, {
      payment_method: {
        card,
        metadata: {
          order_id: orderId,
        },
        billing_details: billingDetails,
      },
    })

    if (result.error) {
      if (result.error.message) addError(result.error.message)
      return false
    }

    // The payment has been processed!
    if (result.paymentIntent.status === 'succeeded') return true

    addError('Payment system issue')
    return false
  }

  return (
    <>
      <PageText>
        Please add your card details: Long card number, expiry date, 3 digit
        security code and postcode/zip code. You may be redirected to your bank
        for further authorisation.
      </PageText>
      <StripeContainer className={errors.length ? 'has-error' : ''}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
              },
            },
          }}
        />
      </StripeContainer>
      <PageNavigation
        back={RoutePath.Basket}
        forward={RoutePath.Confirmation}
        forwardLabel="Pay"
        onForward={handleOrder}
        forwardDisabled={isError}
      />
    </>
  )
}
