import {
  capitalize,
  FieldSize,
  InputField,
  InputType,
  shortVoucherWord,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import { PageSubtitle } from '../../../../components/page/PageElements'
import { useBasket } from '../../../../context/BasketProvider'
import {
  getFieldError,
  getFieldMessage,
} from '../../../../helpers/validityHelper'
import {
  recipientEmailConfirmTest,
  recipientEmailTest,
} from '../../helpers/deliveryTests'
import DispatchDate from './DispatchDate'

export default function EmailForm(props: {
  showErrors: boolean
}): ReactElement {
  const { showErrors } = props
  const { currentItem, currentRecipient, changeCurrentRecipient } = useBasket()
  const { email, emailConfirm } = currentRecipient

  return (
    <>
      <InputField
        label={'Recipient\'s email address'}
        type={InputType.Email}
        onChange={(value): void => changeCurrentRecipient({ email: value })}
        value={email || ''}
        isError={getFieldError(recipientEmailTest, currentItem, showErrors)}
        subtitle={getFieldMessage(recipientEmailTest, currentItem, showErrors)}
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      <InputField
        label={'Confirm recipient\'s email address'}
        type={InputType.Email}
        onChange={(value): void => changeCurrentRecipient({ emailConfirm: value })}
        value={emailConfirm || ''}
        isError={getFieldError(
          recipientEmailConfirmTest,
          currentItem,
          showErrors,
        )}
        subtitle={getFieldMessage(
          recipientEmailConfirmTest,
          currentItem,
          showErrors,
        )}
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      <DispatchDate />
      <PageSubtitle>
        {`Your ${shortVoucherWord(
          currentItem?.format,
        )} will be dispatched in the next five minutes, or choose a
        dispatch date up to three months in the future. ${capitalize(
      shortVoucherWord(currentItem?.format),
    )}s are valid from
        the dispatch date.`}
      </PageSubtitle>
    </>
  )
}
