import React, { ReactElement, ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'

const TitleStyles = styled.h3`
  margin: 20px 0 0;
`
export function PageTitle({
  children,
  style,
  className,
}: {
  children: string
  style?: CSSProperties
  className?: string
}): ReactElement {
  return (
    <TitleStyles style={style} className={className}>
      {children}
    </TitleStyles>
  )
}

const SubtitleStyles = styled.span`
  margin-top: 10px;
`
export function PageSubtitle({ children }: { children: string }): ReactElement {
  return <SubtitleStyles>{children}</SubtitleStyles>
}

const TextStyles = styled.p`
  margin: 5px 0 5px;
`
export function PageText({ children }: { children: ReactNode }): ReactElement {
  return <TextStyles>{children}</TextStyles>
}
