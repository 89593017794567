import { Loading } from '@one-tree/library'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { OnApproveData, OnApproveActions } from '@paypal/paypal-js'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { placeOrder } from '../../helpers/APIHelper'
import { prepareOrder } from '../../helpers/basketHelper'
import { IPayPalOrderResponse } from '../../types/APITypes'
import { IPaymentProps } from '../../types/propTypes'
import { RoutePath } from '../../types/routes'
import PageNavigation from '../page/PageNavigation'

const StyledPayPalButtons = styled(PayPalButtons)`
  &.notLoaded {
    display: none;
  }
`

function RenderButton(props: {
  addError: (error: string) => void
  clearErrors: () => void
  paymentKey: string
}): ReactElement {
  const { addError, clearErrors, paymentKey } = props

  const history = useHistory()
  const [loading, setLoading] = useState(true)

  const onApprove = async (
    data: OnApproveData,
    actions: OnApproveActions,
  ): Promise<void> => {
    clearErrors()

    if (!actions.order) {
      addError('Payment was not approved')
    } else {
      try {
        const res = await actions.order.capture()

        if (res && res.status === 'COMPLETED') {
          // success!
          history.push(RoutePath.Confirmation)
        } else {
          addError('Payment could not be completed')
        }
      } catch (error) {
        // PayPal is generic. You get "Api error" for no funds. This is at least better than that.
        addError('There was an error with your payment')
      }
    }
  }

  return (
    <>
      <StyledPayPalButtons
        className={loading ? 'notLoaded' : ''}
        style={{ layout: 'horizontal' }}
        disabled={loading}
        createOrder={(): Promise<string> => Promise.resolve(paymentKey)}
        onApprove={onApprove}
        onInit={(): void => setLoading(false)}
      />
      {loading && <Loading />}
    </>
  )
}

export default function PayPal(props: IPaymentProps): ReactElement {
  const {
    basketItems,
    purchaser,
    addError,
    clearErrors,
    organisation,
    saveOrder,
  } = props

  const [payPalParams, setPayPalParams] = useState<
    IPayPalOrderResponse | false
  >(false)
  const [orderTotal, setOrderTotal] = useState<number | false>(false)

  useEffect(() => {
    (async (): Promise<void> => {
      if (organisation?.id) {
        // Prepare the order data and submit to One Tree.
        const data = prepareOrder(basketItems, purchaser, organisation.id)
        if (!data) {
          addError('Error with order')
          return
        }
        setOrderTotal(data.orderTotal)

        const res = await placeOrder<IPayPalOrderResponse>(data)

        if (res.error) {
          addError(res.error)
          return
        }
        if (!res.data) {
          addError('No response from server')
          return
        }

        saveOrder(res.data)
        setPayPalParams(res.data)
      }
    })()
  }, [])

  return (
    <>
      {payPalParams && orderTotal && organisation ? (
        <PayPalScriptProvider
          options={{
            'client-id': payPalParams.clientId,
            currency: organisation.currency,
          }}
        >
          <RenderButton
            addError={addError}
            clearErrors={clearErrors}
            paymentKey={payPalParams.paymentKey}
          />
        </PayPalScriptProvider>
      ) : (
        <Loading />
      )}
      <PageNavigation back={RoutePath.Basket} />
    </>
  )
}
