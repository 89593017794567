import {
  getItemColorTheme,
  GridButton,
  OrganisationFormat,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useManualOrder } from '../../context/ManualOrderProvider'
import { useBasket } from '../../context/BasketProvider'
import { homeBasket } from '../router/Router'
import { useOrganisation } from '../../context/OrganisationProvider'

function BasketButton(props: { format: OrganisationFormat }): ReactElement {
  const { format } = props
  const { basketInvalid, basketItems } = useBasket()
  const { manualOrderToken } = useManualOrder()
  const history = useHistory()
  const { organisation } = useOrganisation()

  const theme = getItemColorTheme(organisation)

  return (
    <GridButton
      theme={theme}
      onClick={(): void => {
        history.push(
          homeBasket(
            basketInvalid,
            Boolean(manualOrderToken),
            format,
            basketItems,
          ),
        )
      }}
    >
      Return to basket
    </GridButton>
  )
}
export default BasketButton
