import { getQuestionResponseErrors } from '../../details/questions/questionHelper'
import { IBasketItem } from '../../../types/dataTypes'
import {
  deliveryMethodTest,
  deliveryRecipientTest,
  recipientAddressTest,
  recipientEmailConfirmTest,
  recipientEmailTest,
} from './deliveryTests'
import {
  giftFirstNameTest,
  giftLastNameTest,
  giftMessageFromTest,
} from './personaliseTests'

export const getPersonaliseErrors = (
  basketItem: IBasketItem | undefined,
): string[] => {
  // create an array of errors with a helper function to add our tests
  const errorArray: string[] = []
  const add = (errorTest: (item: IBasketItem) => string | false): void => {
    if (basketItem) {
      const error = errorTest(basketItem)
      if (error) errorArray.push(error)
    } else {
      errorArray.push('Can\'t find basket item')
    }
  }

  add(giftFirstNameTest)
  add(giftLastNameTest)
  add(giftMessageFromTest)

  return errorArray
}

export const getItemErrors = (
  basketItem: IBasketItem | undefined,
): string[] => {
  // create an array of errors with a helper function to add our tests
  const errorArray: string[] = []
  const add = (errorTest: (item: IBasketItem) => string | false): void => {
    if (basketItem) {
      const error = errorTest(basketItem)
      if (error) errorArray.push(error)
    } else {
      errorArray.push('Can\'t find basket item')
    }
  }

  add(giftFirstNameTest)
  add(giftLastNameTest)
  add(giftMessageFromTest)

  add(deliveryMethodTest)
  add(deliveryRecipientTest)
  add(recipientEmailTest)
  add(recipientEmailConfirmTest)
  add(recipientAddressTest)

  return errorArray
}

export const isItemValid = (basketItem: IBasketItem | undefined): boolean => {
  if (
    getItemErrors(basketItem).length
    || getQuestionResponseErrors(basketItem).length
  ) {
    return false
  }
  return true
}
