import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { IBasketItem } from '../../types/dataTypes'

const Styles = styled.div`
  display: grid;
  row-gap: 10px;
`
export default function PostInfo(props: {
  item?: IBasketItem
}): ReactElement | null {
  const { item } = props

  return item && item.postage ? (
    <Styles>
      <div>{item.postage.message}</div>
      <div>{item.postage.description}</div>
    </Styles>
  ) : null
}
