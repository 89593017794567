import {
  Button, ButtonSize, ButtonStyle, colors,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { IBasketItem } from '../../../types/dataTypes'
import { ItemQuestionResponses } from '../../../types/itemTypes'
import Question from './Question'
import { getArray, getQuestionAnswer } from './questionHelper'

const Item = styled.div`
  font-weight: bold;
  column-gap: 5px;
`
const Detail = styled.div`
  margin-top: 3px;
  color: ${colors.darkerGray};
`
const QuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin: 13px 0 30px;
`
const Questions = styled.div`
  margin-bottom: 30px;
  border-bottom: 1px solid ${(props): string => props.theme.linkColor};
  &:last-child {
    border-bottom: none;
  }
`
const Answered = styled.div`
  margin-bottom: 3px;
  opacity: 0.7;

  span {
    margin-left: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const getAnsweredQuestions = (
  item: IBasketItem,
  itemIndex: number,
): ReactElement[] | null => {
  const {
    questionResponses,
    giftItem: { questions },
  } = item
  if (!questions || !questionResponses) return null

  return questions.map((question) => {
    const answerIndex = questionResponses[itemIndex].findIndex(
      ({ questionId }) => questionId === question.id,
    )
    const value = questionResponses[itemIndex][answerIndex].answer

    return (
      <Answered key={question.id}>
        <strong>{`${question.title}:`}</strong>
        <span>{value}</span>
      </Answered>
    )
  })
}

const getQuestions = (
  item: IBasketItem,
  itemIndex: number,
  updateResponse: (
    itemId: string,
    index: number,
    questionId: number,
    answer: string,
  ) => void,
): ReactElement[] | null => {
  const {
    questionResponses,
    giftItem: { questions },
  } = item
  if (!questions || !questionResponses) return null

  return questions.map((question) => {
    const eachResponse = questionResponses[itemIndex]

    if (!eachResponse) return <div>Error</div>

    const answerIndex = eachResponse.findIndex(
      ({ questionId }) => questionId === question.id,
    )

    const value = questionResponses[itemIndex][answerIndex].answer
    const onChange = (answer: string): void => {
      updateResponse(item.id, itemIndex, question.id, answer)
    }

    return (
      <Question
        key={question.id}
        question={question}
        answer={value}
        setAnswer={onChange}
      />
    )
  })
}

type Props = {
  item: IBasketItem
  itemIndex: number
  linkColor: string
  buttonColors: [string, string]
  updateResponse: (
    itemId: string,
    index: number,
    questionId: number,
    answer: string,
  ) => void
  updateResponses: (itemId: string, responses: ItemQuestionResponses) => void
}
export default function QuestionSection(props: Props): ReactElement | null {
  const {
    item,
    itemIndex,
    linkColor,
    buttonColors,
    updateResponse,
    updateResponses,
  } = props

  const {
    quantity, priceName, giftItem, questionResponses,
  } = item
  const { questions } = giftItem

  if (!questions || questions.length === 0) return null

  const quantityHasChanged = item.quantity !== questionResponses?.length

  if (!questionResponses || quantityHasChanged) {
    const initState = questions
      && getArray(quantity).map((_, index) => questions.map(({ id }) => ({
        questionId: id,
        answer: getQuestionAnswer(questionResponses, index, id),
        answered: questionResponses
          ? questionResponses[index]?.some(({ answered }) => answered)
          : false,
      })))
    updateResponses(item.id, initState)
  }

  const price = priceName || ''
  const count = quantity > 1 ? `${itemIndex + 1} of ${quantity}` : ''
  const space = price && count ? ' ' : ''
  const details = `(${price}${space}${count})`
  const renderDetails = (price || count) && <Detail>{details}</Detail>

  const areAllQuestionsAnswered = questionResponses && questionResponses[itemIndex]
    ? questionResponses[itemIndex].every((q) => q.answered)
    : false

  const editQuestion = (index: number): void => {
    if (!questionResponses) return
    const updatedResponses = [...questionResponses]

    updatedResponses[index] = updatedResponses[index].map((question) => ({
      ...question,
      answered: false,
    }))

    updateResponses(item.id, updatedResponses)
  }

  const plural = item.giftItem.questions && item.giftItem.questions.length > 1 ? 's' : ''

  return (
    <Questions key={`${giftItem.id}${details}`}>
      <Item>
        <div>{giftItem.name}</div>
        {renderDetails}
      </Item>
      <QuestionBlock theme={{ linkColor }}>
        {areAllQuestionsAnswered ? (
          <div>
            {getAnsweredQuestions(item, itemIndex)}
            <Button
              buttonStyle={ButtonStyle.Secondary}
              buttonSize={ButtonSize.Mini}
              onClick={(): void => editQuestion(itemIndex)}
              style={{ marginTop: '10px' }}
              customColors={buttonColors}
            >
              {`Edit answer${plural}`}
            </Button>
          </div>
        ) : (
          getQuestions(item, itemIndex, updateResponse)
        )}
      </QuestionBlock>
    </Questions>
  )
}
