import React, { ReactElement } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from '@one-tree/library'
import { BasketProvider } from './context/BasketProvider'
import Personalise from './pages/personalise/Personalise'
import { RoutePath } from './types/routes'
import Home from './pages/Home'
import { PurchaserDataProvider } from './context/PurchaserProvider'
import { OrganisationProvider } from './context/OrganisationProvider'
import { ManualOrderProvider } from './context/ManualOrderProvider'
import { ErrorProvider } from './context/ErrorProvider'
import Router from './components/router/Router'
import Confirmation from './pages/Confirmation'
import Details from './pages/details/Details'
import Payment from './pages/payment/Payment'

const Staging = styled.div`
  background-color: ${colors.orange};
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding: 20px;
  text-align: center;
`
function App(): ReactElement {
  const env = process.env.REACT_APP_API_HOST
  const isStaging = env && env.includes('one-tree.dev/api')

  return (
    <BrowserRouter>
      <ErrorProvider>
        <ManualOrderProvider>
          <OrganisationProvider>
            <PurchaserDataProvider>
              <BasketProvider>
                {isStaging && <Staging>STAGING</Staging>}
                <Router>
                  <Switch>
                    <Route
                      path={RoutePath.Personalise}
                      component={Personalise}
                    />
                    <Route path={RoutePath.Basket} component={Details} />
                    <Route path={RoutePath.Payment} component={Payment} />
                    <Route
                      path={RoutePath.Confirmation}
                      component={Confirmation}
                    />
                    <Route path={`${RoutePath.Home}:id`} component={Home} />
                    <Route path={RoutePath.Home} component={Home} />
                  </Switch>
                </Router>
              </BasketProvider>
            </PurchaserDataProvider>
          </OrganisationProvider>
        </ManualOrderProvider>
      </ErrorProvider>
    </BrowserRouter>
  )
}

export default App
