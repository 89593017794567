import {
  Button,
  ButtonSize,
  ButtonStyle,
  InputGroup,
  Modal,
  ModalPosition,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { useBasket } from '../../context/BasketProvider'

const StyledModal = styled(Modal)`
  text-align: center;
  p {
    margin-top: 0;
    max-width: 260px;
  }
`
interface IDeleteModalProps {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  itemId: string
  onDelete?: () => void
}
export default function DeleteModal(
  props: IDeleteModalProps,
): ReactElement | null {
  const {
    modalOpen, setModalOpen, itemId, onDelete,
  } = props
  const { removeBasketItem } = useBasket()

  const deleteItem = (): void => {
    removeBasketItem(itemId)
    setModalOpen(false)
    if (onDelete) onDelete()
  }

  return modalOpen ? (
    <StyledModal
      modalOpen={modalOpen}
      onModalClose={(): void => setModalOpen(false)}
      modalAction={deleteItem}
      position={ModalPosition.Top}
    >
      <p>Are you sure you want to remove this item from the basket?</p>
      <InputGroup style={{ justifyContent: 'center' }}>
        <Button
          buttonStyle={ButtonStyle.Danger}
          onClick={deleteItem}
          buttonSize={ButtonSize.Large}
        >
          Yes
        </Button>
        <Button
          buttonStyle={ButtonStyle.Secondary}
          onClick={(): void => setModalOpen(false)}
          buttonSize={ButtonSize.Large}
        >
          No
        </Button>
      </InputGroup>
    </StyledModal>
  ) : null
}
