import { Buttonlink, colors, scrollToTop } from '@one-tree/library'
import React, {
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useState,
  createContext,
  useEffect,
} from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Styles = styled.div`
  padding: 20px 10px;
  box-sizing: border-box;
  background-color: ${colors.red};
  color: ${colors.white};
  font-size: 1.15rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .error-row {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    padding: 6px 0;
    svg {
      width: 20px;
      height: auto;
    }
  }
  .error-more {
    padding-top: 10px;
    font-size: 1rem;
  }
`
interface IErrorState {
  errors: string[]
  addError: (error: string) => void
  showErrors: boolean
  setShowErrors: (show: boolean) => void
  clearErrors: () => void
}
const ErrorContext = createContext<IErrorState | undefined>(undefined)

function ErrorProvider({ children }: { children: ReactNode }): ReactElement {
  const [errors, setErrors] = useState<string[]>([])
  const [showFullList, setShowFullList] = useState(false)
  const [showErrors, setShowErrors] = useState(true)

  useEffect(() => {
    if (showErrors && errors.length > 0) scrollToTop()
  }, [showErrors, errors])

  const addError = (error: string): void => {
    setErrors((prevState) => {
      if (!prevState.includes(error)) {
        return [...prevState, error]
      }
      return prevState
    })
  }

  const clearErrors = (): void => setErrors([])

  useEffect(() => {
    // if there are errors, scroll up so the user can see them
    if (showErrors && errors.length > 0) document.documentElement.scrollTop = 0
  }, [showErrors, errors])

  // clear errors when location changes and reset showErrors
  const location = useLocation()
  useEffect(
    () => () => {
      clearErrors()
      setShowErrors(true)
    },
    [location.pathname],
  )

  const value = useMemo(
    () => ({
      errors,
      addError,
      showErrors,
      setShowErrors,
      clearErrors,
    }),
    [errors, showErrors],
  )

  const renderErrors = errors
    .map((error) => (
      <div className="error-row" key={error}>
        <FiAlertCircle />
        <div>{error}</div>
      </div>
    ))
    .slice(0, showFullList ? errors.length : 2)

  const render = errors.length ? (
    <Styles>
      {renderErrors}
      {errors.length > 2 && (
        <Buttonlink
          className="error-more"
          customColor="white"
          onClick={(): void => setShowFullList((prevState) => !prevState)}
        >
          {showFullList ? 'Show less' : 'Show more...'}
        </Buttonlink>
      )}
    </Styles>
  ) : null

  return (
    <ErrorContext.Provider value={value}>
      {showErrors && render}
      {children}
    </ErrorContext.Provider>
  )
}

function useError(): IErrorState {
  const context = useContext(ErrorContext)
  if (context === undefined) {
    throw new Error('useError must be used within ErrorProvider')
  }
  return context
}

export { ErrorProvider, useError }
