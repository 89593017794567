import { getErrorMessage } from '@one-tree/library'
import axios, { AxiosError } from 'axios'
import {
  IOrderPostData,
  IOrderResponse,
  IPreviewPostData,
  Resource,
} from '../types/APITypes'
import { IOrganisationResponse } from '../types/organisationTypes'

export interface IResponse<T> {
  data?: T
  error?: string
}

export const getOrganisation = async (
  id: number,
): Promise<IResponse<IOrganisationResponse>> => {
  const resource = `${Resource.Organisation}/${id}`

  try {
    const { status, data } = await axios.get(resource)
    if (status === 200) return { data }
  } catch (error) {
    return { error: getErrorMessage(error) }
  }

  return { error: `Unknown error: ${resource}` }
}

export const placeOrder = async <T = IOrderResponse>(
  postData: IOrderPostData,
): Promise<IResponse<T>> => {
  const resource = Resource.PlaceOrder

  try {
    const { status, data } = await axios.post(resource, postData)
    if (status === 201) return { data }
  } catch (error) {
    return { error: getErrorMessage(error) }
  }

  return { error: `Unknown error: ${`Unknown error: ${resource}`}` }
}

export type ValidationError = { id: number; description: string }
export const validateOrder = async <T = IOrderResponse>(
  postData: IOrderPostData,
): Promise<IResponse<T> | ValidationError[]> => {
  const resource = Resource.ValidateOrder

  try {
    const { status, data } = await axios.post(resource, postData)
    if (status === 200) return { data }
  } catch (error) {
    // if the error is a validation error, return the object
    if (error instanceof AxiosError && error.response?.status === 400) {
      const errors = error.response.data

      if (errors.find(({ id }: ValidationError) => id)) {
        return error.response.data
      }
    }
    return { error: getErrorMessage(error) }
  }

  return { error: `Unknown error: ${`Unknown error: ${resource}`}` }
}

export const verifyTrust = async (
  jwt: string,
): Promise<IResponse<IOrderResponse>> => {
  const resource = Resource.VerifyTrust

  try {
    const { status, data } = await axios.post(resource, { jwt })
    if (status === 200) return { data }
  } catch (error) {
    return { error: getErrorMessage(error) }
  }

  return { error: `Unknown error: ${resource}` }
}

export const previewPurchase = async (
  item: IPreviewPostData,
): Promise<IResponse<{ image: string }>> => {
  const resource = Resource.Preview

  try {
    const { status, data } = await axios.post(resource, item)
    if (status === 201) return { data }
  } catch (error) {
    return { error: getErrorMessage(error) }
  }

  return { error: `Unknown error: ${resource}` }
}

export const refreshManualOrderToken = async (
  token: string,
): Promise<IResponse<{ token: string }>> => {
  const resource = `${Resource.ManualOrderToken}`

  try {
    const { status, data } = await axios.post(resource, { token })
    if (status === 201) return { data }
  } catch (error) {
    return { error: getErrorMessage(error) }
  }

  return { error: `Unknown error: ${resource}` }
}
