import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { ReactElement } from 'react'
import { OrganisationFormat } from '@one-tree/library'
import Page from '../../components/page/Page'
import { PageTitle } from '../../components/page/PageElements'
import RenderItems from '../../components/page/RenderItems'
import Manual from '../../components/payment/Manual'
import PayPal from '../../components/payment/PayPal'
import Stripe from '../../components/payment/Stripe'
import Trust from '../../components/payment/Trust'
import { useManualOrder } from '../../context/ManualOrderProvider'
import { useBasket } from '../../context/BasketProvider'
import { useError } from '../../context/ErrorProvider'
import { useOrganisation } from '../../context/OrganisationProvider'
import { usePurchaser } from '../../context/PurchaserProvider'
import { PaymentProvider } from '../../types/organisationTypes'
import { IPaymentProps } from '../../types/propTypes'
import { getBasketValue } from '../../helpers/basketHelper'
import Free from '../../components/payment/Free'

function PaymentElement(props: {
  provider?: PaymentProvider
  auth?: boolean
  paymentProps: IPaymentProps
  stripeKey?: string
  allFreeTickets: boolean
}): ReactElement {
  const {
    provider, auth, paymentProps, stripeKey, allFreeTickets,
  } = props

  if (auth) return <Manual {...paymentProps} allFreeTickets={allFreeTickets} />

  if (allFreeTickets) return <Free {...paymentProps} />

  switch (provider) {
  case PaymentProvider.Trust:
    return <Trust {...paymentProps} />
  case PaymentProvider.PayPal:
    return <PayPal {...paymentProps} />
  case PaymentProvider.Stripe:
  default:
    return (
      <Elements stripe={loadStripe(stripeKey || '')}>
        <Stripe {...paymentProps} />
      </Elements>
    )
  }
}

export default function Payment(): ReactElement {
  const { organisation } = useOrganisation()
  const { manualOrderToken } = useManualOrder()
  const { paymentProvider } = organisation || {}
  const { basketItems, saveOrder } = useBasket()
  const { purchaser } = usePurchaser()
  const { errors, addError, clearErrors } = useError()

  const paymentProps: IPaymentProps = {
    basketItems,
    purchaser,
    errors,
    addError,
    clearErrors,
    organisation,
    saveOrder,
  }

  const allFreeTickets = basketItems.length > 0
    && getBasketValue(basketItems) === 0
    && organisation?.format === OrganisationFormat.Tickets

  return (
    <Page>
      <RenderItems
        items={basketItems}
        purchaser={purchaser}
        title="Summary"
        showTotal={true}
      />
      {!allFreeTickets && <PageTitle>Payment</PageTitle>}
      <PaymentElement
        provider={paymentProvider}
        auth={Boolean(manualOrderToken)}
        paymentProps={paymentProps}
        stripeKey={organisation?.paymentKey}
        allFreeTickets={allFreeTickets}
      />
    </Page>
  )
}
