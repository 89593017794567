import { colors } from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

const Styles = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 6px;
  align-items: center;
  @media (max-width: 320px) {
    grid-auto-flow: row;
    row-gap: 2px;
  }
`
const Controls = styled.div`
  border-radius: 4px;
  height: 28px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 5px;
  background-color: ${colors.gray};

  button {
    all: unset;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    &.decrease {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 0 4px 0 6px;
    }
    &.increase {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 0 6px 0 4px;
    }
    :hover {
      background-color: ${colors.darkGray};
    }
    :active {
      background-color: ${colors.darkerGray};
    }
  }
`
interface IIncrementorProps {
  label: string
  count: number
  adjust: (amount: number) => void
  min?: number
  max: number
}
export default function Incrementor(props: IIncrementorProps): ReactElement {
  const {
    label, count, adjust, min = 1, max,
  } = props

  return (
    <Styles>
      <span>{label}</span>
      <Controls>
        <button
          className="decrease"
          type="button"
          onClick={(): void => {
            const newValue = count - 1
            if (newValue >= min) adjust(newValue)
          }}
        >
          &#x2212;
        </button>
        {count}
        <button
          className="increase"
          type="button"
          onClick={(): void => {
            const newValue = count + 1
            if (newValue <= max) adjust(newValue)
          }}
        >
          &#x2B;
        </button>
      </Controls>
    </Styles>
  )
}
