import { getQuestionResponseErrors } from '../pages/details/questions/questionHelper'
import { isItemValid } from '../pages/personalise/helpers/recipientValidity'
import { IBasketItem } from '../types/dataTypes'

export const isBasketValid = (
  basketItems: IBasketItem[],
): boolean => basketItems.map((basketItem) => isItemValid(basketItem)).every((item) => item)

export const getInvalidItems = (basketItems: IBasketItem[]): string[] => {
  const invalidItems: string[] = []

  basketItems.forEach((basketItem) => {
    if (!isItemValid(basketItem) || getQuestionResponseErrors(basketItem).length > 0) {
      invalidItems.push(basketItem.id)
    }
  })

  return invalidItems
}
