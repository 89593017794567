import { OrganisationFormat } from '@one-tree/library'
import { IBasketItem } from '../../../types/dataTypes'

export const giftFirstNameTest = (
  basketItem: IBasketItem | undefined,
): string | false => {
  if (
    !basketItem?.recipient.voucherFirstName
    && basketItem?.format === OrganisationFormat.GiftVouchers
  ) {
    return 'First name required'
  }
  return false
}

export const giftLastNameTest = (
  basketItem: IBasketItem | undefined,
): string | false => {
  if (
    !basketItem?.recipient.voucherLastName
    && basketItem?.format === OrganisationFormat.GiftVouchers
  ) {
    return 'Last name required'
  }
  return false
}

export const giftMessageFromTest = (
  basketItem: IBasketItem | undefined,
): string | false => {
  if (
    !basketItem?.recipient.messageFrom
    && basketItem?.format === OrganisationFormat.GiftVouchers
  ) {
    return 'From message required'
  }
  return false
}
