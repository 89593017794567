import {
  colors,
  OrganisationFormat,
  transformDate,
  TransformDateFormat,
} from '@one-tree/library'
import moment from 'moment'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { useManualOrder } from '../../../context/ManualOrderProvider'
import { getDeliveryInfo } from '../../../helpers/basketHelper'
import { IBasketItem, IPurchaserData } from '../../../types/dataTypes'

const Styles = styled.div`
  color: ${colors.darkerGray};
  margin-top: 5px;
  display: grid;
  row-gap: 2px;
`
interface IItemInfoProps {
  item: IBasketItem
  purchaser?: IPurchaserData
}
export default function ItemInfo(props: IItemInfoProps): ReactElement {
  const { item, purchaser } = props
  const { recipient } = item
  const { voucherFirstName, voucherLastName, dispatchDate } = recipient
  const { manualOrderToken } = useManualOrder()

  const isGiftVoucher = item.format === OrganisationFormat.GiftVouchers

  const isDateInFuture = dispatchDate
    && moment.unix(dispatchDate).endOf('day').unix() > moment().endOf('day').unix()

  const hasName = voucherFirstName || voucherLastName
  const showTo = (isGiftVoucher && hasName) || (isGiftVoucher && manualOrderToken)
  const name = hasName
    ? `${voucherFirstName || ''} ${voucherLastName || ''}`
    : 'Voucher not personalised'

  const deliveryInfo = purchaser && getDeliveryInfo(item, purchaser, Boolean(manualOrderToken))

  return (
    <Styles>
      {showTo && (
        <div>
          <strong>To: </strong>
          {name}
        </div>
      )}
      {deliveryInfo && (
        <div>
          <strong>Delivery: </strong>
          {deliveryInfo}
        </div>
      )}
      {isGiftVoucher && dispatchDate && isDateInFuture && (
        <div>
          <strong>Dispatch: </strong>
          {transformDate(dispatchDate, TransformDateFormat.NumbersAndSlashes)}
        </div>
      )}
    </Styles>
  )
}
