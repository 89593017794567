import { FieldSize, InputField, InputType } from '@one-tree/library'
import React, { ReactElement } from 'react'
import AddressFinder from '../../../../components/addressFinder/AddressFinder'
import {
  PageSubtitle,
  PageTitle,
} from '../../../../components/page/PageElements'
import { useBasket } from '../../../../context/BasketProvider'
import { getFieldError } from '../../../../helpers/validityHelper'
import { recipientAddressTest } from '../../helpers/deliveryTests'
import DispatchDate from './DispatchDate'

export default function PostForm(props: { showErrors: boolean }): ReactElement {
  const { showErrors } = props
  const { currentItem, currentRecipient, changeCurrentRecipient } = useBasket()
  const {
    deliveryFirstName,
    deliveryLastName,
    voucherFirstName,
    voucherLastName,
    addressLineOne,
    addressLineTwo,
    town,
    postcode,
  } = currentRecipient

  return (
    <>
      <InputField
        label={'Recipient\'s first name'}
        type={InputType.Text}
        onChange={(value): void => changeCurrentRecipient({ deliveryFirstName: value })}
        value={deliveryFirstName || ''}
        placeholder={voucherFirstName}
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      <InputField
        label={'Recipient\'s last name'}
        type={InputType.Text}
        onChange={(value): void => changeCurrentRecipient({ deliveryLastName: value })}
        value={deliveryLastName || ''}
        placeholder={voucherLastName}
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      <PageTitle>Recipient&apos;s address</PageTitle>
      <AddressFinder
        lineOne={addressLineOne}
        setLineOne={(value): void => changeCurrentRecipient({ addressLineOne: value })}
        lineTwo={addressLineTwo}
        setLineTwo={(value): void => changeCurrentRecipient({ addressLineTwo: value })}
        town={town}
        setTown={(value): void => changeCurrentRecipient({ town: value })}
        postcode={postcode}
        setPostcode={(value): void => changeCurrentRecipient({ postcode: value })}
        isError={getFieldError(recipientAddressTest, currentItem, showErrors)}
      />
      <DispatchDate />
      <PageSubtitle>
        Choose a dispatch date up to three months in the future. Vouchers are
        valid from the dispatch date.
      </PageSubtitle>
    </>
  )
}
