import { Datepicker, FieldSize } from '@one-tree/library'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { useBasket } from '../../../../context/BasketProvider'
import { useOrganisation } from '../../../../context/OrganisationProvider'

function DispatchDate(): ReactElement {
  const { currentRecipient, changeCurrentRecipient } = useBasket()
  const { linkColor } = useOrganisation()
  const { dispatchDate } = currentRecipient
  const today = moment().unix()

  return (
    <Datepicker
      label="Dispatch date"
      onChange={(date): void => {
        if (date) {
          changeCurrentRecipient({ dispatchDate: date.unix() })
        }
      }}
      value={moment.unix(dispatchDate || today)}
      futureOnly={true}
      maxDate={moment().add(3, 'months')}
      customColor={linkColor}
      fieldSize={FieldSize.Large}
    />
  )
}
export default DispatchDate
