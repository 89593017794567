import {
  Button, ButtonSize, ButtonStyle, InputGroup,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useError } from '../../context/ErrorProvider'
import { useOrganisation } from '../../context/OrganisationProvider'

import { RoutePath } from '../../types/routes'

const Styles = styled.div`
  position: relative;
  margin-top: 10px;
`
const StyledInputGroup = styled(InputGroup)`
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;
`

export interface IPageNavigationProps {
  back?: RoutePath
  onBack?: () => void
  backLabel?: string
  forward?: RoutePath
  forwardLabel?: string
  forwardDisabled?: boolean
  errors?: string[]
  onForward?: () => Promise<boolean> | boolean
  submit?: boolean
  className?: string
}

function PageNavigation(props: IPageNavigationProps): ReactElement {
  const {
    back,
    onBack,
    backLabel,
    forward,
    forwardLabel,
    forwardDisabled,
    errors,
    onForward,
    submit,
    className,
  } = props

  const history = useHistory()
  const { addError, setShowErrors } = useError()
  const [loading, setLoading] = useState(false)

  const forwardHandler = async (): Promise<void> => {
    if (errors && errors.length) {
      errors.forEach((error) => addError(error))
      setShowErrors(true)
      return
    }
    if (onForward) {
      setLoading(true)
      const res = await onForward()
      setLoading(false)
      if (!res) return
    }
    if (forward && !forwardDisabled) {
      history.push(forward)
    }
  }

  const { buttonColors } = useOrganisation()

  return (
    <Styles className={className}>
      <StyledInputGroup>
        {back || onBack || backLabel ? (
          <Button
            onClick={(): void => {
              if (back) {
                history.push(back)
              } else if (onBack) {
                onBack()
              }
            }}
            buttonStyle={ButtonStyle.Secondary}
            buttonSize={ButtonSize.Large}
          >
            {backLabel || 'Back'}
          </Button>
        ) : (
          <div />
        )}
        {(forwardLabel || submit) && (
          <Button
            onClick={forwardHandler}
            buttonStyle={ButtonStyle.Secondary}
            customColors={forwardDisabled ? undefined : buttonColors}
            loading={loading}
            type={submit ? 'submit' : 'button'}
            buttonSize={ButtonSize.Large}
          >
            {forwardLabel || 'Continue'}
          </Button>
        )}
      </StyledInputGroup>
    </Styles>
  )
}
export default PageNavigation
