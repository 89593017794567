import { capitalize, colors } from '@one-tree/library'
import { toWords } from 'number-to-words'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { IBasketItem } from '../../../types/dataTypes'

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Name = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`
const Price = styled.div`
  margin-top: 5px;
  color: ${colors.darkerGray};
  padding-right: 10px;
`
interface IItemNameProps {
  item: IBasketItem
}
export default function ItemName(props: IItemNameProps): ReactElement {
  const { item } = props
  const {
    giftItem, quantity, individuals, priceName,
  } = item
  const { name, maxIndividuals } = giftItem

  const quantityWording = quantity > 1 ? `${quantity} x ` : ''
  const individualsWording = maxIndividuals && maxIndividuals > 1
    ? ` for ${capitalize(toWords(individuals))}`
    : ''

  return (
    <Styles>
      <Name>
        {quantityWording}
        {name}
        {individualsWording}
      </Name>
      {priceName && <Price>{`(${priceName})`}</Price>}
    </Styles>
  )
}
