import React from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle } from 'styled-components'
import axios from 'axios'
import App from './App'

// Import fonts
import '@one-tree/library/dist/index.css'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
  body {
    margin: 0;
    padding:0;
    box-sizing: border-box;
  }
`

axios.defaults.baseURL = process.env.REACT_APP_API_HOST
axios.defaults.headers.post['Content-Type'] = 'application/json'

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
