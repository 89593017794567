import { Radio } from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import { PageTitle } from '../../components/page/PageElements'
import { useBasket } from '../../context/BasketProvider'
import { DeliveryMethod } from '../../types/itemTypes'

export default function ManualDelivery(): ReactElement {
  const { basketItems, setBasketItems } = useBasket()

  const printOption = { value: DeliveryMethod.Print, label: 'Print' }
  const emailOption = { value: DeliveryMethod.Email, label: 'Email' }

  const init = basketItems.some((item) => item.recipient.deliveryMethod === DeliveryMethod.Print)

  const [method, setMethod] = useState(init ? DeliveryMethod.Print : DeliveryMethod.Email)
  const handleDelivery = (deliveryMethod: DeliveryMethod): void => {
    const emailItems = basketItems.map((item) => ({
      ...item,
      recipient: {
        deliveryMethod,
      },
    }))
    setMethod(deliveryMethod)
    setBasketItems(emailItems)
  }

  return (
    <>
      <PageTitle>Delivery method</PageTitle>
      <Radio
        options={[printOption, emailOption]}
        onChange={handleDelivery}
        value={method}
      />
    </>
  )
}
