import { IGiftItemResponse, StateAction } from '@one-tree/library'
import React, {
  ReactElement,
  useState,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import { getInvalidItems, isBasketValid } from '../helpers/basketValidityHelper'
import { getSession, removeSession, setSession } from '../helpers/sessionHelper'
import { IOrderResponse } from '../types/APITypes'
import { CanIncrement, IBasketItem, IRecipientData } from '../types/dataTypes'
import { ItemQuestionResponses } from '../types/itemTypes'
import { RoutePath } from '../types/routes'
import { useError } from './ErrorProvider'

export interface IBasketState {
  basketOpen: boolean
  setBasketOpen: StateAction<boolean>

  basketItems: IBasketItem[]
  setBasketItems: StateAction<IBasketItem[]>
  addBasketItem: (newItem: IBasketItem) => void
  removeBasketItem: (itemId: string) => void
  incrementBasketItem: (
    itemId: string,
    property: keyof CanIncrement,
    amount: number,
    changeTo?: boolean,
  ) => void

  updateQuestionResponses: (
    itemId: string,
    responses: ItemQuestionResponses,
  ) => void
  updateQuestionResponse: (
    itemId: string,
    index: number,
    questionId: number,
    answer: string,
  ) => void
  answerQuestions: () => void

  basketInvalid: boolean
  changeItemValue: (itemId: string, value: number) => void

  currentItem: IBasketItem | undefined
  saveCurrentItem: (newItem: IBasketItem | undefined) => void
  changeCurrentItem: (newItemId?: string) => void

  // convenience helpers to quickly access/change properties of currentItem
  currentGiftItem: IGiftItemResponse
  currentRecipient: IRecipientData
  changeCurrentRecipient: (updates: IRecipientData) => void

  orderId: string | undefined
  pdfUrl: string | undefined
  invoiceUrl: string | undefined
  saveOrder: (order: IOrderResponse) => void
}

const BasketContext = createContext<IBasketState | undefined>(undefined)

function BasketProvider({ children }: { children: ReactNode }): ReactElement {
  const [basketOpen, setBasketOpen] = useState(false)

  const existingBasket = getSession('basket')
  const [basketItems, setBasketItems] = useState<IBasketItem[]>(
    existingBasket ? JSON.parse(existingBasket) : [],
  )

  const existingOrderId = getSession('orderId')
  const [orderId, setOrderId] = useState<string | undefined>(
    existingOrderId || undefined,
  )
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined)
  const [invoiceUrl, setInvoiceUrl] = useState<string | undefined>(undefined)

  const saveOrder = (newOrder: IOrderResponse): void => {
    setSession('orderId', newOrder.orderId)
    setOrderId(newOrder.orderId)
    setPdfUrl(newOrder.pdfUrl)
    setInvoiceUrl(newOrder.invoiceUrl)
  }

  const basketInvalid = !isBasketValid(basketItems)

  const existingCurrentItem = getSession('currentItem')
  const [currentItem, setCurrentItem] = useState<IBasketItem | undefined>(
    existingCurrentItem
      ? basketItems.find((item) => item.id === existingCurrentItem)
      : undefined,
  )
  const saveCurrentItem = (newItem: IBasketItem | undefined): void => {
    if (newItem) {
      setSession('currentItem', newItem.id.toString())
      setCurrentItem(newItem)
    } else {
      removeSession('currentItem')
      setCurrentItem(undefined)
    }
  }

  const history = useHistory()
  const { setShowErrors } = useError()
  const changeCurrentItem = (newItemId?: string): void => {
    document.documentElement.scrollTop = 0
    setShowErrors(false)

    if (newItemId) {
      const newItem = basketItems.find((item) => item.id === newItemId)
      saveCurrentItem(newItem)
      return
    }
    if (basketInvalid) {
      const firstInvalid = getInvalidItems(basketItems)[0]
      const newItem = basketItems.find((item) => item.id === firstInvalid)
      saveCurrentItem(newItem)
      return
    }
    // if all items are valid, go to the summary page
    history.push(RoutePath.Basket)
  }

  useEffect(() => {
    setSession('basket', JSON.stringify(basketItems))
  }, [basketItems])

  const removeBasketItem = (itemId: string): void => {
    setBasketItems((prevState) => {
      // if the item we're removing is the current item, find the next invalid item that isn't this
      // one and change to it. If there are no other invalid items, go to the summary page.
      const withoutItemToDelete = prevState.filter((item) => item.id !== itemId)

      if (itemId === currentItem?.id) {
        const invalidItems = getInvalidItems(withoutItemToDelete)
        const firstInvalid = invalidItems[0]
        const newItem = basketItems.find((item) => item.id === firstInvalid)
        if (newItem) {
          saveCurrentItem(newItem)
        } else {
          history.push(RoutePath.Basket)
        }
      }
      return withoutItemToDelete
    })
  }

  // make the current basket item's giftItem, delivery, and recipient data easily available
  const currentGiftItem = currentItem?.giftItem || {}
  const currentRecipient = currentItem?.recipient || {}

  // update the recipient details of the current item
  const changeCurrentRecipient = (updates: IRecipientData): void => {
    setCurrentItem((prevState) => {
      if (prevState) {
        const recipientUpdates = {
          recipient: { ...prevState.recipient, ...updates },
        }
        return { ...prevState, ...recipientUpdates }
      }
      return undefined
    })
  }

  // update the value of the specified item (for selecting a new value of a Monetary voucher)
  const changeItemValue = (itemId: string, value: number): void => {
    setBasketItems((prevState) => prevState.map((item) => {
      if (item.id === itemId) {
        const updatedItem = { ...item }
        updatedItem.value = value
        if (item.id === currentItem?.id) {
          setCurrentItem(updatedItem)
        }
        return updatedItem
      }
      return item
    }))
  }

  // if the currentItem changes, we should copy these changes back to our basketItems
  useEffect(() => {
    if (currentItem) {
      setBasketItems((prevState) => {
        const items = [...prevState]
        const indexToUpdate = prevState
          .map((item) => item.id)
          .indexOf(currentItem.id)

        // if currentItem not found in basketItems, abort
        if (indexToUpdate < 0) return [...prevState]

        const updatedItem = {
          ...items[indexToUpdate],
          ...currentItem,
        }
        items[indexToUpdate] = updatedItem
        return items
      })
    }
  }, [currentItem])

  const addBasketItem = (newItem: IBasketItem): void => {
    setBasketItems((prevState) => [...prevState, newItem])
    saveCurrentItem(newItem)
  }

  const incrementBasketItem = (
    itemId: string,
    property: keyof CanIncrement,
    amount: number,
    changeTo?: boolean,
  ): void => {
    setBasketItems((prevState) => prevState.map((item) => {
      if (item.id === itemId) {
        const updatedItem = { ...item }
        updatedItem[property] = amount
        if (changeTo || item.id === currentItem?.id) {
          setCurrentItem(updatedItem)
        }
        return updatedItem
      }
      return item
    }))
  }

  const updateQuestionResponses = (
    itemId: string,
    responses: ItemQuestionResponses,
  ): void => {
    setBasketItems((prevState) => prevState.map((item) => {
      if (item.id === itemId) {
        const updatedItem = { ...item }
        updatedItem.questionResponses = responses
        if (item.id === currentItem?.id) {
          setCurrentItem(updatedItem)
        }
        return updatedItem
      }
      return item
    }))
  }

  const updateQuestionResponse = (
    itemId: string,
    index: number,
    questionId: number,
    answer: string,
  ): void => {
    setBasketItems((prevState) => prevState.map((item) => {
      if (item.id === itemId && item.questionResponses) {
        const questionBlockToUpdate = [...item.questionResponses[index]]

        const indexOfQuestionToUpdate = questionBlockToUpdate.findIndex(
          (question) => question.questionId === questionId,
        )

        const updatedQuestion = {
          ...questionBlockToUpdate[indexOfQuestionToUpdate],
          answer,
        }

        questionBlockToUpdate.splice(
          indexOfQuestionToUpdate,
          1,
          updatedQuestion,
        )

        const updatedQuestionResponses = [...item.questionResponses]
        updatedQuestionResponses.splice(index, 1, questionBlockToUpdate)

        const updatedItem = { ...item }

        updatedItem.questionResponses = updatedQuestionResponses

        if (item.id === currentItem?.id) {
          setCurrentItem(updatedItem)
        }

        return updatedItem
      }
      return item
    }))
  }

  const answerQuestions = (): void => {
    setBasketItems((p) => p.map((item) => {
      if (item.questionResponses) {
        const updatedResponses = item.questionResponses.map((response) => {
          const withAnswered = response.map((question) => ({
            ...question,
            answered: Boolean(question.answer),
          }))
          return withAnswered
        })
        return { ...item, questionResponses: updatedResponses }
      }
      return item
    }))
  }

  const value = useMemo(
    () => ({
      basketOpen,
      setBasketOpen,

      basketItems,
      setBasketItems,
      addBasketItem,
      removeBasketItem,
      incrementBasketItem,

      updateQuestionResponses,
      updateQuestionResponse,
      answerQuestions,

      basketInvalid,
      changeItemValue,

      currentItem,
      saveCurrentItem,
      changeCurrentItem,

      currentGiftItem,
      currentRecipient,
      changeCurrentRecipient,

      orderId,
      pdfUrl,
      invoiceUrl,
      saveOrder,
    }),
    [basketOpen, basketItems, currentItem, orderId],
  )

  return (
    <BasketContext.Provider value={value}>{children}</BasketContext.Provider>
  )
}

function useBasket(): IBasketState {
  const context = useContext(BasketContext)
  if (context === undefined) {
    throw new Error('useBasket must be used within a BasketProvider')
  }
  return context
}

export { BasketProvider, useBasket }
