import { OrganisationFormat } from '@one-tree/library'
import React, { ReactElement, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useManualOrder } from '../../context/ManualOrderProvider'
import { useBasket } from '../../context/BasketProvider'
import { RoutePath } from '../../types/routes'
import { IBasketItem } from '../../types/dataTypes'
import { hasQuestions } from '../../pages/details/questions/questionHelper'
import { atHome } from '../../helpers/homeHelper'

export const homeBasket = (
  basketInvalid: boolean,
  auth: boolean | undefined,
  format: OrganisationFormat,
  basketItems: IBasketItem[],
  incomingItem?: IBasketItem,
): RoutePath => {
  // If adding something to basket, and anything at all has questions, always go questions
  if (incomingItem && hasQuestions([...basketItems, incomingItem])) {
    return RoutePath.Personalise
  }

  const giftVouchers = format === OrganisationFormat.GiftVouchers

  const nextUserPage = basketInvalid && giftVouchers ? RoutePath.Personalise : RoutePath.Basket

  return auth ? RoutePath.Basket : nextUserPage
}

export const personaliseBack = (
  basketInvalid: boolean,
  auth: boolean | undefined,
): RoutePath => {
  const nextUserPage = basketInvalid ? RoutePath.Home : RoutePath.Basket

  return auth ? RoutePath.Basket : nextUserPage
}

export default function Router({
  children,
}: {
  children: ReactElement
}): ReactElement {
  const { basketItems, basketInvalid, setBasketOpen } = useBasket()
  const { manualOrderToken } = useManualOrder()
  const { pathname } = useLocation()

  useEffect(() => {
    setBasketOpen(false)
  }, [pathname])

  const { Home, Personalise, Confirmation } = RoutePath

  const editPersonalise = pathname === Personalise

  const editingBasket = atHome(pathname) || editPersonalise

  // if we have no basket items and are not at Confirmation, go home to buy more vouchers
  if (
    basketItems.length < 1
    && !atHome(pathname)
    && pathname !== Confirmation
  ) {
    return <Redirect to={Home} />
  }

  // if we are not on a page where we edit the basket, and the basket is invalid, there has been an
  // error - redirect home.
  if (!editingBasket && basketInvalid && !manualOrderToken) {
    return <Redirect to={Home} />
  }

  return children
}
