import {
  OrganisationFormat,
  shortVoucherWord,
  useFirstRender,
  colors,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import Basket from '../../components/basket/Basket'
import Page from '../../components/page/Page'
import PageNavigation from '../../components/page/PageNavigation'
import { useBasket } from '../../context/BasketProvider'
import { useError } from '../../context/ErrorProvider'
import { useOrganisation } from '../../context/OrganisationProvider'
import { usePurchaser } from '../../context/PurchaserProvider'
import {
  getBasketValue,
  getPurchaserPostageItems,
  hasAllEmail,
  hasAllPost,
  hasAllPrint,
  hasSomeEmail,
  hasSomePost,
  hasSomePrint,
  hasSomePurchaser,
} from '../../helpers/basketHelper'
import { RoutePath } from '../../types/routes'
import { getPurchaserErrors } from './helpers/purchaserValidity'
import Purchaser from './Purchaser'
import ManualDelivery from './ManualDelivery'
import { useManualOrder } from '../../context/ManualOrderProvider'
import { analytics } from '../../helpers/analytics'

const ManualInfo = styled.div`
  margin-top: 20px;
  p {
    font-style: italic;
    margin: 5px 0 0;
    font-size: 0.9rem;
  }
  border: 1px solid ${colors.gray};
  padding: 10px;
  background-color: ${colors.lightestGray};
  border-radius: 5px;
`
export default function Details(): ReactElement | null {
  const { basketItems, saveCurrentItem } = useBasket()
  const {
    organisation, linkColor, buttonColors, currencyCode,
  } = useOrganisation()
  const { showErrors, setShowErrors, clearErrors } = useError()
  const firstRender = useFirstRender()
  const { purchaser, changePurchaser } = usePurchaser()

  useEffect(() => saveCurrentItem(undefined), [])

  const isTicket = organisation?.format === OrganisationFormat.Tickets

  const allPrint = hasAllPrint(basketItems)
  const somePrint = hasSomePrint(basketItems)
  const somePurchaser = hasSomePurchaser(basketItems)
  const allEmail = hasAllEmail(basketItems)
  const someEmail = hasSomeEmail(basketItems)
  const allPost = hasAllPost(basketItems)
  const somePost = hasSomePost(basketItems)

  const isGiftVoucher = organisation?.format === OrganisationFormat.GiftVouchers
  const purchaserRequired = !isGiftVoucher || somePurchaser || !allPrint
  const purchaserPostageItems = getPurchaserPostageItems(basketItems)
  const addressRequired = purchaserPostageItems.length > 0
  const { manualOrderToken } = useManualOrder()
  const purchaserErrors = getPurchaserErrors(
    purchaser,
    addressRequired,
    purchaserRequired,
    organisation?.format,
    Boolean(manualOrderToken),
    allPrint,
  )
  const notValid = purchaserErrors.length > 0

  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (firstRender) setShowErrors(false)
    clearErrors()
    setLoaded(true)
  }, [purchaser])

  const isNotGiftVoucher = !isGiftVoucher
  const hasManualOrderToken = !!manualOrderToken
  const emailRequired = !(isNotGiftVoucher && hasManualOrderToken && allPrint) || isTicket

  const wording = shortVoucherWord(organisation?.format)

  const getManualMessages = (): ReactElement | null => {
    if (!hasManualOrderToken) return null

    const messages: string[] = []
    if (allPrint) {
      messages.push(`All ${wording}s in this order must be printed.`)
    } else if (somePrint) {
      messages.push(`Some ${wording}s in this order must be printed.`)
    }
    if (allEmail) {
      messages.push(`All ${wording}s in this order will be sent via email.`)
    } else if (someEmail) {
      messages.push(`Some ${wording}s in this order will be sent via email.`)
    }
    if (allPost) {
      messages.push(`All ${wording}s in this order will be sent via post.`)
    } else if (somePost) {
      messages.push(`Some ${wording}s in this order will be sent via post.`)
    }

    const editMessage = isGiftVoucher ? `Delivery options can be changed with 'Edit personalisation' for each ${wording} in the basket above.` : null

    return (
      <ManualInfo>
        <strong>Manual order information</strong>
        {messages.map((message) => (
          <p key={message}>{message}</p>
        ))}
        <p>{editMessage}</p>
      </ManualInfo>
    )
  }

  return loaded && organisation ? (
    <Page>
      <Basket items={basketItems} />
      {getManualMessages()}
      {manualOrderToken && !isGiftVoucher && <ManualDelivery />}
      <Purchaser
        organisation={organisation}
        linkColor={linkColor}
        buttonColors={buttonColors}
        showErrors={showErrors}
        purchaser={purchaser}
        changePurchaser={changePurchaser}
        purchaserPostageItems={purchaserPostageItems}
        addressRequired={addressRequired}
        phoneRequired={isTicket}
        emailRequired={emailRequired}
        purchaserRequired={purchaserRequired}
      />
      <PageNavigation
        back={RoutePath.Home}
        forward={RoutePath.Payment}
        forwardLabel="Continue"
        forwardDisabled={notValid}
        errors={purchaserErrors}
        onForward={(): true => {
          analytics(['begin_checkout', 'InitiateCheckout'], organisation, {
            items: basketItems.length,
            value: getBasketValue(basketItems),
            currency: currencyCode,
          })
          return true
        }}
      />
    </Page>
  ) : null
}
