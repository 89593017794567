import { colors, formatCurrency } from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { useOrganisation } from '../../context/OrganisationProvider'
import { getBasketValue, getItemValue } from '../../helpers/basketHelper'
import { IBasketItem, IPurchaserData } from '../../types/dataTypes'
import { DeliveryMethod } from '../../types/itemTypes'
import ItemInfo from '../basket/elements/ItemInfo'
import ItemName from '../basket/elements/ItemName'
import { PageTitle } from './PageElements'

const Items = styled.div`
  display: grid;
  row-gap: 10px;
`
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 2px solid ${colors.lightGray};
`
const Subtitle = styled.div`
  color: ${colors.darkerGray};
  margin-top: 3px;
  &.extra-padding {
    padding-right: 20px;
  }
`
const TotalPrice = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  gap: 8px;
  margin-bottom: 5px;
`
export interface IRenderItemsProps {
  items: IBasketItem[]
  purchaser?: IPurchaserData
  title?: string
  showTotal?: true
}
function RenderItems({
  items,
  purchaser,
  title,
  showTotal,
}: IRenderItemsProps): ReactElement {
  const { currencyCode } = useOrganisation()

  const renderItems = items.map((item) => {
    const { id } = item

    const value = getItemValue(item, false)
    const isPost = item.recipient.deliveryMethod === DeliveryMethod.Post

    return (
      <Item key={id}>
        <div>
          <ItemName item={item} />
          <ItemInfo item={item} purchaser={purchaser} />
        </div>
        <div>
          <div>{formatCurrency(value, currencyCode)}</div>
          <Subtitle style={{ textAlign: 'right' }}>
            {isPost
              ? `+${formatCurrency(item.postage?.cost, currencyCode)}`
              : ''}
          </Subtitle>
        </div>
      </Item>
    )
  })

  return (
    <>
      {title && <PageTitle>{title}</PageTitle>}
      <Items>{renderItems}</Items>
      {showTotal && (
        <TotalPrice>
          <div>Total:</div>
          <div>{formatCurrency(getBasketValue(items), currencyCode)}</div>
        </TotalPrice>
      )}
    </>
  )
}
export default RenderItems
