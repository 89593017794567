import {
  Button,
  formatCurrency,
  shortVoucherWord,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useBasket } from '../../context/BasketProvider'
import { useOrganisation } from '../../context/OrganisationProvider'
import { getBasketValue, isNotLastItem } from '../../helpers/basketHelper'
import { hasQuestions } from '../../pages/details/questions/questionHelper'
import { IBasketItem } from '../../types/dataTypes'
import { RoutePath } from '../../types/routes'
import BasketItem from './BasketItem'

const Styles = styled.div`
  position: relative;
`
const Title = styled.div`
  margin-bottom: 10px;
  color: ${(props): string => props.theme};
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.2rem;
  span {
    font-weight: normal;
  }
`
const BasketControls = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 10px;
`
const BasketControl = styled.div`
  text-align: center;
  display: block;
  margin-top: 15px;
  color: ${(props): string => props.theme};
`
interface IBasketProps {
  items: IBasketItem[]
}
export default function Basket(props: IBasketProps): ReactElement {
  const { items } = props
  const {
    linkColor, buttonColors, currencyCode, organisation,
  } = useOrganisation()
  const history = useHistory()
  const { pathname } = useLocation()
  const { basketItems, changeCurrentItem } = useBasket()

  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const renderTitle = (
    <Title theme={linkColor}>
      <div>Your basket</div>
      <div>
        <span>Total: </span>
        {`${formatCurrency(getBasketValue(items), currencyCode)}`}
      </div>
    </Title>
  )

  const renderItems = items.map((item, index) => (
    <BasketItem
      key={item.id}
      index={index}
      basketItem={item}
      openIndex={openIndex}
      setIsOpen={(open): void => setOpenIndex(open ? index : null)}
    />
  ))

  const canAddAnother = items.length < 8 && organisation && isNotLastItem(basketItems, organisation)

  const renderAddButton = canAddAnother ? (
    <BasketControl theme={linkColor}>
      <Button
        customColors={buttonColors}
        onClick={(): void => history.push(RoutePath.Home)}
      >
        {`Add another ${shortVoucherWord(organisation?.format)}`}
      </Button>
    </BasketControl>
  ) : (
    'Basket full'
  )

  const renderQuestionsButton = hasQuestions(basketItems) && (
    <BasketControl theme={linkColor}>
      <Button
        customColors={buttonColors}
        onClick={(): void => {
          changeCurrentItem(basketItems[0].id)
          history.push(RoutePath.Personalise)
        }}
      >
        Edit questions
      </Button>
    </BasketControl>
  )

  return (
    <Styles>
      {items.length > 1 && renderTitle}
      {renderItems}
      {pathname !== RoutePath.Personalise && (
        <BasketControls>
          {renderQuestionsButton}
          {renderAddButton}
        </BasketControls>
      )}
    </Styles>
  )
}
