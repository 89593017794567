import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { FaUserEdit, FaShoppingCart, FaCreditCard } from 'react-icons/fa'
import { colors, OrganisationFormat } from '@one-tree/library'
import { useLocation } from 'react-router-dom'
import { useOrganisation } from '../../context/OrganisationProvider'
import { RoutePath } from '../../types/routes'
import { useBasket } from '../../context/BasketProvider'
import { hasQuestions } from '../../pages/details/questions/questionHelper'

const Indicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`
const Icon = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 100%;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 25px;
    height: auto;
  }
`
const Thread = styled.div`
  height: 3px;
  width: 50px;
  @media (max-width: 320px) {
    width: 25px;
  }
`
const Title = styled.div`
  position: absolute;
  bottom: -26px;
`
const Styles = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 60px;
  --base: ${colors.darkGray};
  --active: ${(props): string => props.theme};
  ${Icon} {
    background-color: var(--base);
  }
  .step-active > ${Icon} {
    background-color: var(--active);
  }
  ${Thread} {
    background-color: var(--base);
    &.step-active {
      background-color: var(--active);
    }
  }
  ${Title} {
    color: var(--base);
  }
  .step-active > ${Title} {
    color: var(--active);
    font-weight: bold;
  }
`
const BasketCount = styled.div`
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: ${colors.red};
  color: ${colors.white};
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
`
export default function Progress(): ReactElement {
  const { Personalise, Basket, Payment } = RoutePath
  const { organisation, linkColor } = useOrganisation()
  const { basketItems } = useBasket()

  const steps = [
    {
      name: 'Personalise',
      path: Personalise,
      icon: <FaUserEdit style={{ marginLeft: '4px' }} />,
    },
    { name: 'Thread' },
    {
      name: 'Basket',
      path: Basket,
      icon: <FaShoppingCart style={{ margin: '1px 0 0 -2px' }} />,
      items: basketItems.length,
    },
    { name: 'Thread' },
    {
      name: 'Checkout',
      path: Payment,
      icon: <FaCreditCard />,
    },
  ]

  const { format } = organisation || {}
  const hasPersonalise = format === OrganisationFormat.GiftVouchers || hasQuestions(basketItems)
  const trimmedSteps = !hasPersonalise ? steps.slice(2) : steps

  const location = useLocation()
  const path = location.pathname
  const activeStep = trimmedSteps.findIndex((step) => step.path === path)

  const renderSteps = trimmedSteps.map((step, index) => {
    const isActive = index === activeStep

    const key = `${index}`
    const className = isActive ? 'step-active' : ''
    const props = { key, className }

    if (step.name === 'Thread') return <Thread {...props} />

    return (
      <Indicator {...props}>
        <Icon>{step.icon}</Icon>
        <Title>{step.name}</Title>
        {step.items ? <BasketCount>{step.items}</BasketCount> : null}
      </Indicator>
    )
  })

  return <Styles theme={linkColor}>{renderSteps}</Styles>
}
