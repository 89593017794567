import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { useBasket } from '../../../context/BasketProvider'
import { useOrganisation } from '../../../context/OrganisationProvider'
import QuestionSection from './QuestionSection'

const Title = styled.h3`
  margin: 0;
`
const Instruction = styled.p`
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props): string => props.theme.linkColor};
`

export default function Questions(): ReactElement {
  const { basketItems, updateQuestionResponses, updateQuestionResponse } = useBasket()
  const { linkColor, buttonColors } = useOrganisation()

  const allQuestions: (ReactElement | null)[] = []

  basketItems.forEach((item) => {
    Array(item.quantity)
      .fill(null)
      .forEach((_, index) => {
        allQuestions.push(
          <QuestionSection
            item={item}
            itemIndex={index}
            linkColor={linkColor}
            buttonColors={buttonColors}
            updateResponse={updateQuestionResponse}
            updateResponses={updateQuestionResponses}
          />,
        )
      })
  })

  const wording = allQuestions.length > 1 ? 'each of your tickets.' : 'your ticket.'

  return (
    <div>
      <Title>Questions</Title>
      <Instruction theme={{ linkColor }}>
        {`Please answer the following questions regarding ${wording}`}
      </Instruction>
      {allQuestions}
    </div>
  )
}
