import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { colors, Hyperlink } from '@one-tree/library'
import { PaymentProvider } from '../types/organisationTypes'
import PayPal from '../assets/paypal.png'
import Trust from '../assets/trust.png'
import Stripe from '../assets/stripe.png'
import packageJson from '../../package.json'
import { useManualOrder } from '../context/ManualOrderProvider'

const Styles = styled.div`
  display: grid;
  color: ${colors.darkestGray};
  font-weight: bold;
  font-size: 12px;
  row-gap: 8px;
  padding: 40px 10px;
  div {
    display: flex;
    justify-content: center;
    a {
      text-decoration: none;
      margin-left: 3px;
      display: flex;
    }
    img {
      height: 18px;
      margin-left: 2px;
    }
  }
`
const WrapContainer = styled.div`
  flex-wrap: wrap;
  row-gap: 2px;
`

export interface IFooterProps {
  paymentProvider: PaymentProvider
}
function Footer(props: IFooterProps): ReactElement {
  const { paymentProvider } = props
  const { manualOrderToken } = useManualOrder()

  const getPaymentLogo = (): ReactNode => {
    switch (paymentProvider) {
    case PaymentProvider.PayPal:
      return (
        <Hyperlink href="https://www.paypal.com/" newTab={true}>
          <img src={PayPal} alt="PayPal" />
        </Hyperlink>
      )
    case PaymentProvider.Trust:
      return (
        <Hyperlink href="https://www.trustpayments.com/" newTab={true}>
          <img src={Trust} alt="Trust Payments" />
        </Hyperlink>
      )
    case PaymentProvider.Stripe:
    default:
      return (
        <Hyperlink href="https://www.stripe.com/" newTab={true}>
          <img src={Stripe} alt="Stripe" />
        </Hyperlink>
      )
    }
  }

  return (
    <Styles>
      {!manualOrderToken && (
        <>
          <WrapContainer title={`Version: ${packageJson.version}`}>
            Powered by
            <Hyperlink
              href="https://www.one-tree.net/"
              customColor={colors.logo}
              newTab={true}
            >
              One Tree Software
            </Hyperlink>
          </WrapContainer>
          <WrapContainer>
            Safe payments handled by
            {getPaymentLogo()}
          </WrapContainer>
        </>
      )}
    </Styles>
  )
}
export default Footer
