import { ISelectOption, ITicketQuestion, QuestionType } from '@one-tree/library'
import moment from 'moment'
import { IBasketItem } from '../../../types/dataTypes'
import { ItemQuestionResponses } from '../../../types/itemTypes'

export const getArray = (size: number): string[] => Array(size).fill('')

export const getQuestionAnswer = (
  questionResponses: ItemQuestionResponses | undefined,
  blockNumber: number,
  questionId: number,
): string => {
  if (questionResponses && questionResponses[blockNumber]) {
    const thisResponse = questionResponses[blockNumber].find(
      (question) => question.questionId === questionId,
    )
    return thisResponse?.answer || ''
  }
  return ''
}

type Option = { label: string; value: string }
export const getOptions = (
  options: string | Option[] | undefined,
): Option[] => {
  if (typeof options === 'object') return options

  const optionsArray = options && options.split('\n')
  return optionsArray
    ? optionsArray.map((option) => {
      const value = option.split(':')[0].trim()
      const label = option.split(':')[1].trim()
      return {
        label,
        value,
      }
    })
    : []
}

export const getQuestionErrors = (
  question: ITicketQuestion,
  answer?: string,
): string | false => {
  if (question.required && !answer) {
    return 'Question is required'
  }

  if (question.type === QuestionType.Number) {
    if (answer && question.max && Number.parseFloat(answer) > question.max) {
      return `Maximum number is ${question.max}`
    }
    if (answer && question.min && Number.parseFloat(answer) < question.min) {
      return `Minimum number is ${question.min}`
    }
  } else if (question.min && (!answer || answer.length < question.min)) {
    return 'Answer is too short'
  }

  if (question.type === QuestionType.Date) {
    if (answer && !moment(answer, 'DD/MM/YYYY', true).isValid()) {
      return 'Answer must be DD/MM/YYYY'
    }
  }
  return false
}

export const checkEveryQuestion = (
  questions: ITicketQuestion[],
  allAnswers: ItemQuestionResponses,
): string[] => {
  const errors: string[] = []
  allAnswers.forEach((answerBlock) => {
    questions.forEach((question) => {
      const thisAnswer = answerBlock.filter(
        (answer) => answer.questionId === question.id,
      )[0].answer

      const error = getQuestionErrors(question, thisAnswer)
      if (error) {
        errors.push(error)
      }
    })
  })
  return errors
}

export const getQuestionResponseErrors = (item?: IBasketItem): string[] => {
  const errors: string[] = []
  if (item && item.giftItem.questions && item.questionResponses) {
    errors.push(
      ...checkEveryQuestion(item.giftItem.questions, item.questionResponses),
    )
  }
  return errors
}

export const hasQuestions = (basketItems: IBasketItem[]): boolean => basketItems.filter(
  (item) => item.giftItem.questions && item.giftItem.questions?.length > 0,
).length > 0

export const getAllQuestionErrors = (items: IBasketItem[]): string[] => {
  const errors: string[] = []
  items.forEach((item) => {
    errors.push(...getQuestionResponseErrors(item))
  })
  return errors
}

export const orderAnswer = (string: string, array: ISelectOption[]): string => {
  const stringValues = string.split(', ')
  const orderedValues = array.map(({ value }) => value)

  return stringValues
    .sort((a, b) => orderedValues.indexOf(a) - orderedValues.indexOf(b))
    .join(', ')
}
