import {
  formatCurrency,
  OrganisationFormat,
  Radio,
  shortVoucherWord,
  stripEmptyFromArray,
} from '@one-tree/library'
import React, { ReactElement, useEffect } from 'react'
import { PageSubtitle } from '../../../components/page/PageElements'
import { useBasket } from '../../../context/BasketProvider'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { getFieldError } from '../../../helpers/validityHelper'
import { DeliveryMethod } from '../../../types/itemTypes'
import { deliveryMethodTest } from '../helpers/deliveryTests'

export default function Method(props: {
  showErrors: boolean
  setShowErrors: (showErrors: boolean) => void
  auth?: boolean
}): ReactElement | null {
  const { showErrors, setShowErrors, auth } = props
  const { currentItem, currentRecipient, changeCurrentRecipient } = useBasket()

  const { deliveryMethod } = currentRecipient

  const { organisation, currencyCode } = useOrganisation()
  const { emailDelivery, postDelivery } = organisation || {}

  const isVoucherOrg = organisation?.format === OrganisationFormat.GiftVouchers

  const emailOption = { value: DeliveryMethod.Email, label: 'Email (free)' }
  const postOption = {
    value: DeliveryMethod.Post,
    label: `Post (${formatCurrency(
      currentItem?.postage?.cost || 0,
      currencyCode,
    )} per ${shortVoucherWord(currentItem?.format)})`,
  }
  const printOption = { value: DeliveryMethod.Print, label: 'Print' }
  const options = stripEmptyFromArray([
    auth ? printOption : null,
    emailOption,
    postOption,
  ])

  useEffect(() => {
    if (!isVoucherOrg || !postDelivery) {
      changeCurrentRecipient({ deliveryMethod: DeliveryMethod.Email })
    } else if (!emailDelivery) {
      changeCurrentRecipient({ deliveryMethod: DeliveryMethod.Post })
    }
  }, [currentItem?.id])

  return isVoucherOrg && emailDelivery && postDelivery ? (
    <>
      <PageSubtitle>How should the voucher be sent?</PageSubtitle>
      <Radio
        options={options}
        onChange={(value): void => {
          changeCurrentRecipient({ deliveryMethod: value })
          setShowErrors(false)
        }}
        value={deliveryMethod}
        isError={getFieldError(deliveryMethodTest, currentItem, showErrors)}
      />
    </>
  ) : null
}
