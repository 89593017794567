import {
  colors,
  CurrencyCode,
  formatCurrency,
  theme,
  ZIndex,
} from '@one-tree/library'
import React, {
  ReactElement, useState, useRef, useEffect,
} from 'react'
import styled from 'styled-components'

const Styles = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  @media (max-width: 320px) {
    display: grid;
    row-gap: 2px;
    align-content: center;
  }
`
const Container = styled.div`
  margin-left: 5px;
  @media (max-width: 320px) {
    margin-left: 0;
  }
  position: relative;
  font-size: 0.9rem;
`
const SelectButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  min-width: 60px;
  overflow-x: hidden;
  column-gap: 5px;
  border: none;
  border-radius: 4px;
  height: 28px;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  background-color: ${colors.gray};
  padding-left: 7px;
  .select-button-arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid ${colors.black};
    margin-top: 1px;
  }
  :hover {
    background-color: ${colors.darkGray};
  }
  &.selected {
    background-color: ${colors.darkGray};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 162px;
  border: 1px solid ${colors.darkGray};
  background-color: ${colors.white};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow-y: scroll;
  ${theme.itemScrollbar};
  position: absolute;
  z-index: ${ZIndex.AboveContent};
  width: 100%;
  box-sizing: border-box;
  font-size: inherit;
`
const DropdownItem = styled.div`
  cursor: pointer;
  padding: 5px;
  font-size: inherit;
  &.dropdown-selected {
    background-color: ${colors.lightGray};
  }
  :hover {
    background-color: ${colors.gray};
    color: ${colors.black};
  }
`
interface ICurrencyDropdownProps {
  label: string
  options: string[]
  selectedValue: number
  selectValue: (value: number) => void
  currencyCode: CurrencyCode
}
export default function CurrencyDropdown(
  props: ICurrencyDropdownProps,
): ReactElement {
  const {
    label, options, selectedValue, selectValue, currencyCode,
  } = props

  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const contentRef = useRef<HTMLDivElement>(null)
  const handleClickOutside = (mouseDownEvent: MouseEvent): void => {
    const ref = contentRef.current
    const { target } = mouseDownEvent

    if (target instanceof Node && ref && !ref.contains(target)) {
      setShowDropdown(false)
    }
  }
  const handleKeydown = (keyDownEvent: KeyboardEvent): void => {
    if (keyDownEvent.code === 'Escape') setShowDropdown(false)
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeydown)
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeydown)
    }
  }, [contentRef])

  const handleSelectValue = (value: number): void => {
    selectValue(value)
    setShowDropdown(false)
  }

  return (
    <Styles>
      <span>{label}</span>
      <Container ref={contentRef}>
        <SelectButton
          onClick={(): void => setShowDropdown((prevState) => !prevState)}
          className={showDropdown ? 'selected' : ''}
        >
          {formatCurrency(selectedValue, currencyCode, true)}
          <div className="select-button-arrow" />
        </SelectButton>
        {showDropdown && (
          <Menu>
            {options.map((price) => {
              const value = parseFloat(price)

              return (
                <DropdownItem
                  key={value}
                  onClick={(): void => handleSelectValue(value)}
                  className={selectedValue === value ? 'dropdown-selected' : ''}
                >
                  {formatCurrency(value, currencyCode, true)}
                </DropdownItem>
              )
            })}
          </Menu>
        )}
      </Container>
    </Styles>
  )
}
