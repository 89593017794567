export const getFieldError = <T = unknown>(
  itemTest: (item: T) => string | false,
  item: T | undefined,
  show: boolean = true,
): boolean => (item && show ? Boolean(itemTest(item)) : false)

export const getFieldMessage = <T = unknown>(
  itemTest: (item: T) => string | false,
  item: T | undefined,
  show: boolean = true,
): string => (item && show ? itemTest(item) || '' : '')

export const hasUndefined = (object: {}): boolean => Object.values(object).includes(undefined)
