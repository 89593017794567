import {
  colors,
  IGiftItemResponse,
  Item,
  ItemGridContainer,
  Loading,
  OrganisationFormat,
  scrollToTop,
  theme,
  useNotFirstRender,
} from '@one-tree/library'
import axios from 'axios'
import React, { ReactElement, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import Footer from '../components/Footer'
import BasketButton from '../components/header/BasketButton'
import { homeBasket } from '../components/router/Router'
import { useBasket } from '../context/BasketProvider'
import { useError } from '../context/ErrorProvider'
import { useManualOrder } from '../context/ManualOrderProvider'
import { useOrganisation } from '../context/OrganisationProvider'
import { usePurchaser } from '../context/PurchaserProvider'
import { analytics } from '../helpers/analytics'
import {
  addQuestionsIfPrice,
  getNameIfPrice,
  getUniqueId,
  removeInvalid,
} from '../helpers/basketHelper'
import {
  getIncrementError,
  getTESOManualDeliveryMethod,
} from '../helpers/homeHelper'
import {
  clearSessions,
  getSessionNumber,
  removeSession,
  setSession,
} from '../helpers/sessionHelper'
import { DeliveryMethod, RecipientType } from '../types/itemTypes'
import { RoutePath } from '../types/routes'

const GlobalStyle = createGlobalStyle`
  body {
    ${theme.scrollbar};
    overflow: overlay;
  }
`
const Styles = styled.div`
  background-color: ${(props): string => `#${props.theme.colourPageBackground}`};
`
const BasketButtonContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Error = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 50px;
  color: ${colors.red};
  font-weight: bold;
`
function Home(): ReactElement {
  const history = useHistory()
  const {
    basketItems, setBasketItems, addBasketItem, incrementBasketItem,
  } = useBasket()
  const { setPurchaser } = usePurchaser()
  const {
    currencyCode, organisation, selectOrganisation, destroyOrg,
  } = useOrganisation()
  const { id }: { id: string | undefined } = useParams()
  const { addError } = useError()
  const { manualOrderToken, getManualOrderToken, setManualOrderToken } = useManualOrder()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const requestToken = queryParams.get('token')

  // set up embeds
  const queryCategory = queryParams.get('category')
  const incomingCategory = Number(queryCategory)
  if (incomingCategory) removeSession('itemEmbed')
  const existingCategory = getSessionNumber('categoryEmbed')
  const categoryToEmbed = incomingCategory || existingCategory || undefined

  const queryItem = queryParams.get('item')
  const incomingItem = Number(queryItem)
  if (incomingItem) removeSession('categoryEmbed')
  const existingItem = getSessionNumber('itemEmbed')
  const itemToEmbed = incomingItem || (!categoryToEmbed && existingItem)
  const embeddedItem = organisation?.items?.find(
    (item) => item.id === itemToEmbed,
  )

  const noEmbed = queryParams.has('noembed')

  if (noEmbed) {
    removeSession('categoryEmbed')
    removeSession('itemEmbed')
  }

  const noOrgMatch = id && organisation && id !== organisation.id.toString()

  const killAll = (): void => {
    destroyOrg()
    setBasketItems([])
    setPurchaser({})
  }

  const notFirstRender = useNotFirstRender()
  useEffect(() => {
    if (notFirstRender) scrollToTop()
  }, [])

  useEffect(() => {
    (async (): Promise<void> => {
      if (noOrgMatch) {
        killAll()
        window.location.reload()
        return
      }

      // save our starting points if embedded
      if (queryCategory) {
        setSession('categoryEmbed', queryCategory)
      }
      if (queryItem) {
        setSession('itemEmbed', queryItem)
      }

      if (requestToken) {
        const newToken = await getManualOrderToken(requestToken)

        if (!newToken) {
          addError('Manual order error')
          killAll()
          return
        }

        const newOrgNoMatch = organisation && newToken.id !== organisation.id.toString()
        const newOrgNoQueryMatch = !organisation && id && newToken.id !== id

        if (newOrgNoMatch) {
          addError('Switched to a new organisation')
          killAll()
          return
        }

        if (newOrgNoQueryMatch) {
          addError('Server organisation mismatch')
          killAll()
          return
        }

        if (newToken.role === 'manual-order') {
          setSession('manualOrderToken', newToken.token)
          axios.defaults.headers.common.Authorization = `Bearer ${newToken.token}`
          setManualOrderToken(newToken.token)
        }
      }

      const changingOrg = id && organisation && organisation.id !== Number.parseInt(id, 10)

      const existingOrg = organisation?.id
        ? organisation.id.toString()
        : undefined

      if (id || existingOrg) {
        if (changingOrg) {
          clearSessions()
          setBasketItems([])
          setPurchaser({})
          selectOrganisation(undefined)
        }
        await selectOrganisation(id || existingOrg)
      } else if (!organisation) {
        addError('Embed error - organisation not found')
      }
    })()
  }, [id, requestToken])

  // when on home, delete any items in the basket with unfinished personalisation
  useEffect(() => {
    if (
      !manualOrderToken
      || organisation?.format === OrganisationFormat.Tickets
    ) {
      setBasketItems((prevState) => removeInvalid(prevState))
    }
  }, [])

  if (noOrgMatch) return <div />
  if (!id && !organisation) return <div />

  const waitForToken = Boolean(requestToken) && !manualOrderToken
  if (!organisation || waitForToken) {
    return <Loading fullPage={true} />
  }

  const { format, postage, font } = organisation

  const formatGiftVoucher = format === OrganisationFormat.GiftVouchers
  const TESODefaults = {
    deliveryMethod: !formatGiftVoucher ? DeliveryMethod.Email : undefined,
    recipientType: !formatGiftVoucher ? RecipientType.Purchaser : undefined,
  }
  const manualDefaults = {
    deliveryMethod: !formatGiftVoucher
      ? getTESOManualDeliveryMethod(basketItems)
      : DeliveryMethod.Print,
  }
  const addToBasket = (
    giftItem: IGiftItemResponse,
    value: number,
    quantity: number,
    individuals: number,
  ): void => {
    // Check if we are adding a non-Price duplicate, so we can increment quantity etc
    const duplicateBasketItem = basketItems.find(
      (item) => item.giftItem.id === giftItem.id,
    )
    let itemQuantity = duplicateBasketItem?.giftItem.quantity
    let itemQuantitySold = duplicateBasketItem?.giftItem.quantitySold
    let itemMaxPerOrder = duplicateBasketItem?.giftItem.maxPerOrder
    let basketQuantity = duplicateBasketItem?.quantity || 0

    // If we are adding a Price, check if there are any other Price Tickets in the basket already
    const addingAPrice = Boolean(giftItem.originalId)
    if (addingAPrice) {
      const duplicates = basketItems.filter(
        (item) => item.originalId === giftItem.originalId,
      )
      basketQuantity = duplicates.reduce(
        (total, each) => total + each.quantity,
        0,
      )
      // If we already have at least one of the parent Ticket (a Price variant) in the basket
      if (duplicates[0]) {
        // Since the Ticket stock is fixed, can pull these from any duplicate
        itemQuantity = duplicates[0].giftItem.quantity
        itemQuantitySold = duplicates[0].giftItem.quantitySold
        itemMaxPerOrder = duplicates[0].giftItem.maxPerOrder
      }
    }

    const incrementError = getIncrementError(
      itemQuantity,
      itemQuantitySold,
      itemMaxPerOrder,
      basketQuantity,
      quantity,
    )
    if (incrementError) {
      addError(`Cannot purchase ${quantity} more. ${incrementError}.`)
      return
    }
    if (basketItems.length >= 8) {
      addError(
        'Maximum order size reached, please checkout your existing basket first',
      )
      return
    }

    analytics(['add_to_cart', 'AddToCart'], organisation, {
      name: giftItem.name,
      id: giftItem.id,
      value: value * quantity * individuals,
      currency: currencyCode,
    })

    // If we are simply adding on to the quantity of an existing basket item
    if (!formatGiftVoucher && duplicateBasketItem) {
      incrementBasketItem(
        duplicateBasketItem.id,
        'quantity',
        duplicateBasketItem.quantity + quantity,
        true,
      )

      const destination = homeBasket(
        true,
        Boolean(manualOrderToken),
        organisation.format,
        basketItems,
        duplicateBasketItem,
      )

      history.push(destination, {
        from: RoutePath.Home,
      })
      return
    }

    // Otherwise, add the new item
    const newItem = {
      id: getUniqueId(),
      giftItem: addQuestionsIfPrice(giftItem),
      recipient: manualOrderToken ? manualDefaults : TESODefaults,
      value,
      quantity,
      individuals,
      format,
      postage,
      priceName: getNameIfPrice(giftItem),
      originalId: giftItem.originalId,
    }

    addBasketItem(newItem)

    // basket always invalid when adding new item to basket
    history.push(
      homeBasket(
        true,
        Boolean(manualOrderToken),
        organisation.format,
        basketItems,
        newItem,
      ),
      {
        from: RoutePath.Home,
      },
    )
  }

  const itemsInBasket = basketItems.length

  const renderEmbeddedItem = embeddedItem ? (
    <Item
      giftItem={embeddedItem}
      currency={currencyCode}
      addToBasket={addToBasket}
      organisationColors={organisation}
      font={font}
      embedMode={true}
    />
  ) : (
    <Error>Item not found</Error>
  )

  const render = itemToEmbed && !noEmbed ? (
    renderEmbeddedItem
  ) : (
    <ItemGridContainer
      giftItems={organisation.items}
      categories={organisation.categories}
      categoryImages={organisation.categoryImages || false}
      defaultCategory={!noEmbed ? categoryToEmbed : undefined}
      currency={currencyCode}
      addToBasket={addToBasket}
      organisationColors={organisation}
      introductoryText={organisation?.introductoryText}
      introductoryTextSize={organisation?.textOnBackgroundSize}
      font={font}
    />
  )

  return (
    <Styles theme={organisation}>
      <GlobalStyle />
      {itemsInBasket > 0 && (
        <BasketButtonContainer>
          <BasketButton format={format} />
        </BasketButtonContainer>
      )}
      {render}
      <Footer paymentProvider={organisation.paymentProvider} />
    </Styles>
  )
}
export default Home
