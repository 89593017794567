import { Radio, shortVoucherWord } from '@one-tree/library'
import React, { ReactElement } from 'react'
import { PageSubtitle } from '../../../components/page/PageElements'
import { useBasket } from '../../../context/BasketProvider'
import { getFieldError } from '../../../helpers/validityHelper'
import { RecipientType } from '../../../types/itemTypes'
import { deliveryRecipientTest } from '../helpers/deliveryTests'

export default function Recipient(props: {
  showErrors: boolean
  setShowErrors: (showErrors: boolean) => void
  auth?: boolean
}): ReactElement {
  const { showErrors, auth, setShowErrors } = props
  const { currentItem, currentRecipient, changeCurrentRecipient } = useBasket()
  const { recipientType } = currentRecipient

  return (
    <>
      <PageSubtitle>
        {`Who should the ${shortVoucherWord(currentItem?.format)} be sent to?`}
      </PageSubtitle>
      <Radio
        options={[
          {
            value: RecipientType.Purchaser,
            label: auth ? 'The customer' : 'Me',
          },
          { value: RecipientType.Recipient, label: 'The recipient' },
        ]}
        onChange={(value): void => {
          changeCurrentRecipient({ recipientType: value })
          setShowErrors(false)
        }}
        value={recipientType}
        isError={getFieldError(deliveryRecipientTest, currentItem, showErrors)}
      />
    </>
  )
}
