import { getMaxQuantity, getRemaining } from '@one-tree/library'
import { IBasketItem } from '../types/dataTypes'
import { DeliveryMethod } from '../types/itemTypes'
import { RoutePath } from '../types/routes'

export const getIncrementError = (
  quantityRemaining: number | null | undefined,
  quantitySold: number | undefined,
  maxPerOrder: number | null | undefined,
  quantityInBasket: number,
  quantityAddingNow: number,
): string | false => {
  const remaining = getRemaining(quantityRemaining, quantitySold)
  const maxQuantity = getMaxQuantity(remaining, maxPerOrder)
  const maxAllowedLeftInOrder = maxQuantity - quantityInBasket

  if (maxAllowedLeftInOrder < quantityAddingNow) {
    const message = maxAllowedLeftInOrder > 0
      ? `Only ${maxAllowedLeftInOrder} left`
      : 'None left'
    return message
  }
  return false
}

export const getTESOManualDeliveryMethod = (
  basketItems: IBasketItem[],
): DeliveryMethod => {
  const hasEmail = basketItems.some(
    (item) => item.recipient.deliveryMethod === DeliveryMethod.Email,
  )
  return hasEmail ? DeliveryMethod.Email : DeliveryMethod.Print
}

export const atHome = (pathname: string): boolean => {
  // This is so when an organisation ID is included on the Home route we still know
  // we are at home. regex matches only `/` with 0 to 4 digits afterwards.
  const regex = /^\/[0-9]{0,4}$/
  return Boolean(pathname.match(regex)) || pathname === RoutePath.Home
}
