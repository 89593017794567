import {
  FieldSize,
  InputField,
  InputType,
  useFirstRender,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { useBasket } from '../../context/BasketProvider'
import { useError } from '../../context/ErrorProvider'
import { placeOrder } from '../../helpers/APIHelper'
import { prepareOrder } from '../../helpers/basketHelper'
import { IPaymentProps } from '../../types/propTypes'
import { RoutePath } from '../../types/routes'
import { PageText } from '../page/PageElements'
import PageNavigation from '../page/PageNavigation'

export default function Manual(
  props: { allFreeTickets: boolean } & IPaymentProps,
): ReactElement {
  const {
    basketItems,
    purchaser,
    addError,
    clearErrors,
    organisation,
    allFreeTickets,
  } = props
  const { showErrors, setShowErrors } = useError()
  const firstRender = useFirstRender()
  const { saveOrder } = useBasket()

  const [paymentReference, setPaymentReference] = useState('')

  useEffect(() => {
    if (firstRender) setShowErrors(false)
    clearErrors()
  }, [paymentReference])

  const paymentReferenceTest = (): string | false => {
    if (!paymentReference) return 'Please enter a reference'
    return false
  }

  const handleOrder = async (): Promise<boolean> => {
    setShowErrors(true)

    if (!organisation) return false
    const data = prepareOrder(
      basketItems,
      purchaser,
      organisation.id,
      paymentReference,
    )
    if (!data) {
      addError('Error with order')
      return false
    }
    const res = await placeOrder(data)

    if (res.error) {
      addError(res.error)
      return false
    }
    if (!res.data) {
      addError('No response from server')
      return false
    }

    saveOrder(res.data)
    return true
  }

  const error = paymentReferenceTest()

  return (
    <>
      {!allFreeTickets && (
        <PageText>
          Please take an offline payment at this stage before you enter the
          payment reference.
        </PageText>
      )}
      <InputField
        type={InputType.Text}
        placeholder={allFreeTickets ? 'Order reference' : 'Payment reference'}
        value={paymentReference}
        onChange={setPaymentReference}
        isError={showErrors ? Boolean(paymentReferenceTest()) : false}
        subtitle={showErrors ? paymentReferenceTest() || '' : ''}
        fieldSize={FieldSize.Large}
      />
      <PageNavigation
        back={RoutePath.Basket}
        forward={RoutePath.Confirmation}
        forwardLabel="Complete order"
        onForward={handleOrder}
        forwardDisabled={Boolean(error)}
        errors={error ? [error] : []}
      />
    </>
  )
}
