import { colors, CurrencyCode } from '@one-tree/library'
import axios from 'axios'
import React, {
  ReactElement,
  useState,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useEffect,
} from 'react'
import ReactGA4 from 'react-ga4'
import { useLocation } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import { getOrganisation } from '../helpers/APIHelper'
import {
  clearSessions,
  getSession,
  removeSession,
  setSession,
} from '../helpers/sessionHelper'
import { IOrganisationResponse } from '../types/organisationTypes'
import { useError } from './ErrorProvider'
import { useManualOrder } from './ManualOrderProvider'
import { atHome } from '../helpers/homeHelper'

export interface IOrganisationState {
  organisation: IOrganisationResponse | undefined
  selectOrganisation: (id: string | undefined) => Promise<boolean>
  currencyCode: CurrencyCode
  linkColor: string
  buttonColors: [string, string]
  destroyOrg: (error?: string) => void
}

const OrganisationContext = createContext<IOrganisationState | undefined>(
  undefined,
)

function OrganisationProvider({
  children,
}: {
  children: ReactNode
}): ReactElement {
  const { addError } = useError()

  const existingOrganisation = getSession('organisation')
  const init = existingOrganisation ? JSON.parse(existingOrganisation) : undefined
  const [organisation, setOrganisation] = useState(init)

  // Analytics
  const location = useLocation()
  const { gaTrackingId, fbPixelId } = organisation || {}
  const { manualOrderToken } = useManualOrder()

  useEffect(() => {
    if (manualOrderToken) return

    const page = atHome(location.pathname) ? '/home' : location.pathname

    if (gaTrackingId) {
      ReactGA4.initialize(gaTrackingId)
      ReactGA4.send({ hitType: 'pageview', page })
    }
    if (fbPixelId) {
      ReactPixel.init(fbPixelId)
      ReactPixel.track('ViewContent', { page })
    }
  }, [gaTrackingId, fbPixelId, location.pathname])

  const currencyCode = organisation ? organisation?.currency : CurrencyCode.GBP

  const selectOrganisation = async (
    id: string | undefined,
  ): Promise<boolean> => {
    if (id) {
      const orgId = Number.parseInt(id, 10)
      const res = await getOrganisation(orgId)
      if (res.data) {
        setOrganisation(res.data)
        setSession('organisation', JSON.stringify(res.data))
        return true
      }
      if (res.error) {
        addError(res.error)
        return false
      }
    } else {
      setOrganisation(undefined)
      removeSession('organisation')
      return true
    }
    addError(`Organisation ${id} not found`)
    return false
  }

  const destroyOrg = (): void => {
    clearSessions()
    delete axios.defaults.headers.common.Authorization
    selectOrganisation(undefined)
  }

  // if there is a colourHeaderText, it will have been set because the colourHighlight is not
  // visible on a white background (i.e. orgs that have a light button with dark text on it)
  const link = organisation
    && (organisation.colourHeaderText || organisation.colourHighlight)

  const linkColor = `#${link}`

  const backColor = organisation && organisation.colourHighlight
  const textColor = organisation && organisation.colourTextOnHighlight

  const buttonColors: [string, string] = backColor && textColor
    ? [`#${backColor}`, `#${textColor}`]
    : [colors.blue, colors.white]

  const value = useMemo(
    () => ({
      organisation,
      selectOrganisation,
      currencyCode,
      linkColor,
      buttonColors,
      destroyOrg,
    }),
    [organisation, selectOrganisation, currencyCode],
  )

  return (
    <OrganisationContext.Provider value={value}>
      {children}
    </OrganisationContext.Provider>
  )
}

function useOrganisation(): IOrganisationState {
  const context = useContext(OrganisationContext)
  if (context === undefined) {
    throw new Error(
      'useOrganisation must be used within an OrganisationProvider',
    )
  }
  return context
}

export { OrganisationProvider, useOrganisation }
