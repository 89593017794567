import { StateAction } from '@one-tree/library'
import React, {
  ReactElement,
  useState,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useEffect,
} from 'react'
import { getSession, setSession } from '../helpers/sessionHelper'
import { IPurchaserData } from '../types/dataTypes'

export interface IUserDataState {
  purchaser: IPurchaserData
  changePurchaser: (updates: IPurchaserData) => void
  setPurchaser: StateAction<IPurchaserData>
}

const UserDataContext = createContext<IUserDataState | undefined>(undefined)

function PurchaserProvider({
  children,
}: {
  children: ReactNode
}): ReactElement {
  const existingPurchaserData = getSession('purchaser')
  const [purchaser, setPurchaser] = useState<IPurchaserData>(
    existingPurchaserData
      ? JSON.parse(existingPurchaserData)
      : {
        // set defaults
        joinMailingList: false,
        agreedToTerms: false,
      },
  )

  useEffect(() => {
    setSession('purchaser', JSON.stringify(purchaser))
  }, [purchaser])

  const changePurchaser = (updates: IPurchaserData): void => {
    setPurchaser((prevState) => ({ ...prevState, ...updates }))
  }

  const value = useMemo(
    () => ({
      purchaser,
      changePurchaser,
      setPurchaser,
    }),
    [purchaser],
  )

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  )
}

function usePurchaser(): IUserDataState {
  const context = useContext(UserDataContext)
  if (context === undefined) {
    throw new Error(
      'usePurchaserData must be used within a PurchaserDataProvider',
    )
  }
  return context
}

export { PurchaserProvider as PurchaserDataProvider, usePurchaser }
