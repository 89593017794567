import { stripEmptyFromObject } from '@one-tree/library'
import ReactGA4 from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { IOrganisationResponse } from '../types/organisationTypes'

export const analytics = (
  event: [string, string],
  organisation?: IOrganisationResponse,
  payload?: Object,
): void => {
  if (!organisation) return
  const { gaTrackingId, fbPixelId } = organisation

  const object = stripEmptyFromObject(payload || {})

  if (gaTrackingId) ReactGA4.event(event[0], object)
  if (fbPixelId) ReactPixel.track(event[1], object)
}
