import { OrganisationFormat } from '@one-tree/library'
import { IPurchaserData } from '../../../types/dataTypes'
import {
  purchaserAddressTest,
  purchaserEmailConfirmTest,
  purchaserEmailTest,
  purchaserFirstNameTest,
  purchaserLastNameTest,
  purchaserPhoneTest,
  purchaserTermsTest,
} from './purchaserTests'

export const getPurchaserErrors = (
  purchaser: IPurchaserData,
  addressRequired: boolean,
  purchaserRequired: boolean,
  format: OrganisationFormat | undefined,
  isManualOrder: boolean,
  allPrint: boolean,
): string[] => {
  if (!purchaserRequired || !format) return []

  const errorArray: string[] = []

  if (purchaser && addressRequired) {
    const error = purchaserAddressTest(purchaser, addressRequired)
    if (error) errorArray.push(error)
  }

  const isTicket = format === OrganisationFormat.Tickets
  const isTESO = format !== OrganisationFormat.GiftVouchers

  if (isTicket) {
    const error = purchaserPhoneTest(purchaser, isTicket)
    if (error) errorArray.push(error)
  }

  const nameRequired = !isManualOrder || isTicket
  if (nameRequired) {
    const firstError = purchaserFirstNameTest(purchaser, nameRequired)
    const lastError = purchaserLastNameTest(purchaser, nameRequired)
    if (firstError) errorArray.push(firstError)
    if (lastError) errorArray.push(lastError)
  }

  const emailNotRequired = isTESO && isManualOrder && allPrint
  if (!emailNotRequired || isTicket) {
    const error = purchaserEmailTest(purchaser, !emailNotRequired || isTicket)
    if (error) errorArray.push(error)
  }

  const confirmRequired = !isManualOrder
  if (confirmRequired) {
    const error = purchaserEmailConfirmTest(purchaser, confirmRequired)
    if (error) errorArray.push(error)
  }

  const termsRequired = !isManualOrder
  if (termsRequired) {
    const error = purchaserTermsTest(purchaser, termsRequired)
    if (error) errorArray.push(error)
  }

  return errorArray
}
