import {
  Checkbox,
  Datepicker,
  InputField,
  InputType,
  ITicketQuestion,
  QuestionType,
  Radio,
  TextArea,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useError } from '../../../context/ErrorProvider'
import {
  getArray,
  getOptions,
  getQuestionErrors,
  orderAnswer,
} from './questionHelper'

const QuestionTitle = styled.div`
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 0.9rem;
`
const MultiChoice = styled.div`
  display: grid;
  row-gap: 2px;
`

function Checkboxes({
  options,
  answer,
  setAnswer,
}: {
  options: string
  answer: string
  setAnswer: (answer: string) => void
}): ReactElement {
  const optionObject = getOptions(options)
  const values = getArray(optionObject.length)

  const handleChange = (index: number, value: boolean): void => {
    const update = [...values]
    if (value) {
      update[index] = optionObject[index].value

      const newCheckValue = update.filter(Boolean)[0]
      if (newCheckValue) {
        if (!answer) {
          setAnswer(newCheckValue)
        } else {
          setAnswer(`${answer}, ${newCheckValue}`)
        }
      }
    } else {
      const thingToRemove = optionObject[index].value
      const updatedAnswer = answer
        .split(', ')
        .filter((thisValue) => thisValue !== thingToRemove)
        .join(', ')
      setAnswer(orderAnswer(updatedAnswer, optionObject))
    }
  }

  return (
    <MultiChoice>
      {optionObject
        && optionObject.map((option, index) => (
          <Checkbox
            key={option.value}
            label={option.label}
            value={answer.includes(option.value)}
            onChange={(value): void => handleChange(index, value)}
          />
        ))}
    </MultiChoice>
  )
}

export default function Question({
  question,
  answer,
  setAnswer,
}: {
  question: ITicketQuestion
  answer: string
  setAnswer: (answer: string) => void
}): ReactElement {
  const { showErrors } = useError()

  const questionName = `${question.title} ${question.required ? '*' : ''}`

  const fieldProps = {
    maxChars: question.max,
    hideMaxChars: true,
    label: questionName,
    isError: showErrors && Boolean(getQuestionErrors(question, answer)),
    subtitle: (showErrors && getQuestionErrors(question, answer)) || '',
    value: answer,
    onChange: setAnswer,
  }

  if (question.type === QuestionType.Textarea) {
    return <TextArea {...fieldProps} />
  }

  if (question.type === QuestionType.Checkbox) {
    if (!question.options) return <div>no options</div>
    return (
      <div>
        <QuestionTitle>{questionName}</QuestionTitle>
        <Checkboxes
          options={question.options}
          answer={answer}
          setAnswer={setAnswer}
        />
      </div>
    )
  }

  if (question.type === QuestionType.Phone) {
    return <InputField {...fieldProps} type={InputType.Phone} />
  }

  if (question.type === QuestionType.Radio) {
    if (!question.options) return <div>no options</div>
    return (
      <div>
        <QuestionTitle>{questionName}</QuestionTitle>
        <Radio
          options={getOptions(question.options)}
          value={answer}
          onChange={setAnswer}
          isError={Boolean(getQuestionErrors(question, answer))}
        />
      </div>
    )
  }

  if (question.type === QuestionType.Number) {
    return (
      <InputField
        {...fieldProps}
        type={InputType.Number}
        maxChars={undefined}
      />
    )
  }

  if (question.type === QuestionType.Date) {
    return (
      <Datepicker
        {...fieldProps}
        onChange={(date): void => {
          setAnswer(date ? date.format('DD/MM/YYYY') : '')
        }}
        value={moment(answer, 'DD/MM/YYYY')}
        isClearable={true}
      />
    )
  }

  return <InputField type={InputType.Text} {...fieldProps} />
}
