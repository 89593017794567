import {
  Button,
  ButtonSize,
  capitalize,
  colors,
  InputGroup,
  itemWord,
  Loading,
  OrganisationFormat,
  shortVoucherWord,
  voucherWord,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Page from '../components/page/Page'
import { PageText, PageTitle } from '../components/page/PageElements'
import PostInfo from '../components/page/PostInfo'
import RenderItems from '../components/page/RenderItems'
import { useManualOrder } from '../context/ManualOrderProvider'
import { useBasket } from '../context/BasketProvider'
import { useOrganisation } from '../context/OrganisationProvider'
import { usePurchaser } from '../context/PurchaserProvider'
import {
  getBasketValue,
  getEmailItems,
  getPostageItems,
} from '../helpers/basketHelper'
import { IBasketItem, IPurchaserData } from '../types/dataTypes'
import { RoutePath } from '../types/routes'
import { analytics } from '../helpers/analytics'

const ConfirmationBox = styled.div`
  background-color: ${colors.lightGray};
  border-radius: 4px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border: 2px solid;
  border-color: ${(props): string => (props.theme.length ? props.theme : colors.blue)};
  font-weight: bold;
  font-size: 1.2rem;
`

interface ConfirmationButtonProps {
  pdfUrl: string | undefined
  invoiceUrl: string | undefined
  plural: string
}

function ConfirmationButtons({
  pdfUrl,
  invoiceUrl,
  plural,
}: ConfirmationButtonProps): ReactElement {
  const { organisation, buttonColors } = useOrganisation()
  const format = organisation?.format

  return (
    <InputGroup style={{ justifyContent: 'center' }}>
      {pdfUrl && (
        <Button
          customColors={buttonColors}
          buttonSize={ButtonSize.Large}
          onClick={(): void => {
            window.open(pdfUrl, '_blank')
          }}
        >
          {`Print ${voucherWord(format)}${plural ? 's' : ''}`}
        </Button>
      )}
      {invoiceUrl && (
        <Button
          customColors={buttonColors}
          buttonSize={ButtonSize.Large}
          onClick={(): void => {
            window.open(invoiceUrl, '_blank')
          }}
        >
          Print order confirmation
        </Button>
      )}
    </InputGroup>
  )
}

function Confirmation(): ReactElement {
  const history = useHistory()
  const {
    basketItems, setBasketItems, orderId, pdfUrl, invoiceUrl,
  } = useBasket()
  const {
    organisation, buttonColors, linkColor, currencyCode,
  } = useOrganisation()
  const { manualOrderToken } = useManualOrder()
  const { setPurchaser } = usePurchaser()

  const [purchasedItems, setPurchasedItems] = useState<IBasketItem[]>([])
  const [purchaserDetails, setPurchaserDetails] = useState<IPurchaserData>({})
  const [loading, setLoading] = useState(true)

  const plural = purchasedItems.length > 1 ? 's' : ''
  const format = organisation?.format
  const manualText = plural
    ? `${voucherWord(format)}s that may need`
    : `a ${voucherWord(format)} that may need`

  const emailItems = getEmailItems(purchasedItems)
  const postageItems = getPostageItems(purchasedItems)

  useEffect(() => {
    // Page feels weird without loading state
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    analytics(['purchase', 'Purchase'], organisation, {
      orderId,
      items: basketItems.length,
      value: getBasketValue(basketItems),
      currency: currencyCode,
    })

    if (!basketItems.length && !purchasedItems.length) {
      history.push(RoutePath.Home)
    }

    // If we have basket items, copy into our confirmation page and then clear
    if (basketItems.length) {
      setBasketItems((prevState) => {
        setPurchasedItems(prevState)
        return []
      })
    }

    // Same for purchaser
    if (purchaserDetails) {
      setPurchaser((prevState) => {
        setPurchaserDetails(prevState)
        return {}
      })
    }
  }, [])

  const isGiftVoucher = format === OrganisationFormat.GiftVouchers
  const emailText = isGiftVoucher
    ? 'If ordered for dispatch today, the email will be sent in the next five minutes. Orders for a date in the future will be sent out on the day requested.'
    : 'The email will be sent in the next five minutes.'

  return loading ? (
    <Page>
      <PageTitle style={{ margin: ' 0 auto' }}>
        {`Completing ${manualOrderToken ? 'the' : 'your'} order...`}
      </PageTitle>
      <Loading fullPage={true} />
    </Page>
  ) : (
    <Page>
      <ConfirmationBox theme={linkColor}>
        <div>Order complete!</div>
        <div style={{ textAlign: 'right' }}>
          <span style={{ fontWeight: 'normal' }}>Order ID: </span>
          {orderId}
        </div>
      </ConfirmationBox>
      {!manualOrderToken && (
        <PageText>
          We have sent you an email to confirm your purchase. If the email has
          not arrived in your inbox within a few minutes, please check your spam
          and promotions folders.
        </PageText>
      )}

      {purchasedItems ? (
        <RenderItems
          items={purchasedItems}
          purchaser={purchaserDetails}
          title={`${capitalize(itemWord(format))}${plural} purchased`}
          showTotal={true}
        />
      ) : (
        <Loading />
      )}

      {emailItems.length > 0 && (
        <PageText>
          <b>{`Email ${shortVoucherWord(format)}s `}</b>
          <br />
          {`Sent out as downloadable attachments. ${emailText}`}
        </PageText>
      )}

      {postageItems.length > 0 && (
        <PageText>
          <b>{`Postal ${shortVoucherWord(format)}s `}</b>
          <br />
          <PostInfo item={postageItems[0]} />
        </PageText>
      )}

      {manualOrderToken ? (
        <>
          <PageText>
            <strong>{`This order contains ${manualText} to be printed at the desk. \n`}</strong>
            You can print again at any time by going to the Sales page.
          </PageText>
          <ConfirmationButtons
            pdfUrl={pdfUrl}
            invoiceUrl={invoiceUrl}
            plural={plural}
          />
        </>
      ) : (
        <PageText>Thank you for your order.</PageText>
      )}

      <Button
        buttonSize={ButtonSize.Large}
        style={{ margin: '0 auto' }}
        onClick={(): void => history.push(RoutePath.Home)}
        customColors={buttonColors}
      >
        {`Back to ${itemWord(format)}s`}
      </Button>
    </Page>
  )
}

export default Confirmation
