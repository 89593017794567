import {
  Button,
  Buttonlink,
  ButtonSize,
  ButtonStyle,
  capitalize,
  Checkbox,
  FieldSize,
  InputField,
  InputGroup,
  InputType,
  Modal,
  ModalPosition,
  OrganisationFormat,
  PhoneField,
  scrollToTop,
  shortVoucherWord,
  theme,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import AddressFinder from '../../components/addressFinder/AddressFinder'
import { PageTitle } from '../../components/page/PageElements'
import PostInfo from '../../components/page/PostInfo'
import { useManualOrder } from '../../context/ManualOrderProvider'
import { IBasketItem, IPurchaserData } from '../../types/dataTypes'
import { IOrganisationResponse } from '../../types/organisationTypes'
import {
  purchaserAddressTest,
  purchaserEmailConfirmTest,
  purchaserEmailTest,
  purchaserFirstNameTest,
  purchaserLastNameTest,
  purchaserPhoneTest,
  purchaserTermsTest,
} from './helpers/purchaserTests'

const ModalContent = styled.div`
  padding: 0 10px 10px;
  max-width: 500px;
  .modal-buttons {
    margin-top: 20px;
    justify-content: space-between;
  }
  h3 {
    margin: 10px 0 5px;
  }
`
const Terms = styled.div`
  overflow-y: auto;
  max-height: 450px;
  ${theme.scrollbar};
`
interface IPurchaserProps {
  organisation: IOrganisationResponse
  linkColor: string
  buttonColors: [string, string]
  showErrors: boolean
  purchaser: IPurchaserData
  changePurchaser: (updates: IPurchaserData) => void
  purchaserPostageItems: IBasketItem[]
  addressRequired: boolean
  phoneRequired: boolean
  emailRequired: boolean
  purchaserRequired: boolean
}
export default function Purchaser(props: IPurchaserProps): ReactElement {
  const {
    organisation,
    linkColor,
    buttonColors,
    showErrors,
    purchaser,
    changePurchaser,
    purchaserPostageItems,
    addressRequired,
    phoneRequired,
    emailRequired,
    purchaserRequired,
  } = props

  const {
    purchaserFirstName,
    purchaserLastName,
    purchaserEmail,
    purchaserEmailConfirm,
    purchaserPhone,
    purchaserAddressLineOne,
    purchaserAddressLineTwo,
    purchaserTown,
    purchaserPostcode,
    joinMailingList,
    agreedToTerms,
  } = purchaser

  const { manualOrderToken } = useManualOrder()

  const [showTerms, setShowTerms] = useState(false)
  const [showMailingList, setShowMailingList] = useState(false)
  useEffect(() => {
    if (showTerms || showMailingList) scrollToTop()
  }, [showTerms, showMailingList])

  const getName = (upper?: boolean): string => {
    const string = manualOrderToken ? 'the customer\'s' : 'your'
    if (upper) return capitalize(string)
    return string
  }

  const customerWord = manualOrderToken ? 'the customer' : 'you'
  const articleWord = manualOrderToken ? 'the' : 'our'
  const mailingWords = `Tick this box if ${customerWord} would like to join ${articleWord}`

  const termsString = manualOrderToken
    ? 'Does the customer agree to the'
    : 'Do you agree to the'

  const optionalText = purchaserRequired ? '' : 'Optional - '

  const { format } = organisation
  const { Tickets } = OrganisationFormat
  const required = !manualOrderToken || format === Tickets

  return (
    <>
      <PageTitle>{`${optionalText}${getName(true)} details`}</PageTitle>
      <InputField
        label={`${getName(true)} first name`}
        type={InputType.Text}
        onChange={(value): void => changePurchaser({ purchaserFirstName: value })}
        value={purchaserFirstName || ''}
        isError={
          showErrors
            ? Boolean(purchaserFirstNameTest(purchaser, required))
            : false
        }
        subtitle={
          showErrors
            ? purchaserFirstNameTest(purchaser, required) || ''
            : undefined
        }
        autoComplete="given-name"
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      <InputField
        label={`${getName(true)} last name`}
        type={InputType.Text}
        onChange={(value): void => changePurchaser({ purchaserLastName: value })}
        value={purchaserLastName || ''}
        isError={
          showErrors
            ? Boolean(purchaserLastNameTest(purchaser, required))
            : false
        }
        subtitle={
          showErrors
            ? purchaserLastNameTest(purchaser, required) || ''
            : undefined
        }
        autoComplete="family-name"
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      <InputField
        label={`${getName(true)} email address`}
        type={InputType.Email}
        onChange={(value): void => changePurchaser({ purchaserEmail: value })}
        value={purchaserEmail || ''}
        isError={
          showErrors
            ? Boolean(purchaserEmailTest(purchaser, emailRequired))
            : false
        }
        subtitle={
          showErrors
            ? purchaserEmailTest(purchaser, emailRequired) || ''
            : undefined
        }
        autoComplete="email"
        fieldSize={FieldSize.Large}
        maxChars={60}
        hideMaxChars={true}
      />
      {!manualOrderToken && (
        <InputField
          label={`Confirm ${getName()} email address`}
          type={InputType.Email}
          onChange={(value): void => changePurchaser({ purchaserEmailConfirm: value })}
          value={purchaserEmailConfirm || ''}
          isError={
            showErrors
              ? Boolean(purchaserEmailConfirmTest(purchaser, !manualOrderToken))
              : false
          }
          subtitle={
            showErrors
              ? purchaserEmailConfirmTest(purchaser, !manualOrderToken) || ''
              : undefined
          }
          autoComplete="email"
          fieldSize={FieldSize.Large}
          maxChars={60}
          hideMaxChars={true}
        />
      )}
      <PhoneField
        label={`${getName(true)} telephone number`}
        onChange={(phoneNumber): void => changePurchaser({ purchaserPhone: phoneNumber })}
        phoneNumber={purchaserPhone}
        isError={
          showErrors
            ? Boolean(purchaserPhoneTest(purchaser, phoneRequired))
            : false
        }
        subtitle={
          showErrors
            ? purchaserPhoneTest(purchaser, phoneRequired) || ''
            : undefined
        }
        autoComplete={true}
        fieldSize={FieldSize.Large}
      />
      {addressRequired && (
        <>
          <PageTitle>{`${getName(true)} address`}</PageTitle>
          <PostInfo item={purchaserPostageItems[0]} />
          <AddressFinder
            lineOne={purchaserAddressLineOne}
            setLineOne={(value): void => changePurchaser({ purchaserAddressLineOne: value })}
            lineTwo={purchaserAddressLineTwo}
            setLineTwo={(value): void => changePurchaser({ purchaserAddressLineTwo: value })}
            town={purchaserTown}
            setTown={(value): void => changePurchaser({ purchaserTown: value })}
            postcode={purchaserPostcode}
            setPostcode={(value): void => changePurchaser({ purchaserPostcode: value })}
            isError={
              showErrors
                ? Boolean(purchaserAddressTest(purchaser, addressRequired))
                : false
            }
          />
        </>
      )}
      {!manualOrderToken && (
        <>
          <Checkbox
            onChange={(value): void => changePurchaser({ joinMailingList: value })}
            value={joinMailingList || false}
            label={mailingWords}
            link={(
              <Buttonlink
                onClick={(): void => setShowMailingList(true)}
                customColor={linkColor}
              >
                mailing list
              </Buttonlink>
            )}
            fieldSize={FieldSize.Large}
          />
          <Checkbox
            onChange={(value): void => changePurchaser({ agreedToTerms: value })}
            value={agreedToTerms || false}
            label={termsString}
            link={(
              <Buttonlink
                onClick={(): void => setShowTerms(true)}
                customColor={linkColor}
              >
                terms and conditions?
              </Buttonlink>
            )}
            isError={
              showErrors
                ? Boolean(purchaserTermsTest(purchaser, !manualOrderToken))
                : false
            }
            fieldSize={FieldSize.Large}
          />
        </>
      )}
      <Modal
        modalOpen={showMailingList}
        onModalClose={(): void => setShowMailingList(false)}
        position={ModalPosition.Top}
      >
        <ModalContent>
          <h3>Privacy information</h3>
          <p>
            We respect the privacy of your personal data. Please read our
            privacy policy to find out more.
          </p>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => setShowMailingList(false)}
            buttonSize={ButtonSize.Large}
          >
            Close
          </Button>
        </ModalContent>
      </Modal>

      <Modal
        modalOpen={showTerms}
        onModalClose={(): void => setShowTerms(false)}
        position={ModalPosition.Top}
      >
        <ModalContent>
          <Terms>
            {organisation?.termsAndConditions && (
              <h3>
                {`${capitalize(shortVoucherWord(organisation?.format))} Terms`}
              </h3>
            )}
            {organisation?.termsAndConditions}
            {organisation?.purchaserTerms && <h3>Purchaser Terms</h3>}
            {organisation?.purchaserTerms}
          </Terms>
          <InputGroup className="modal-buttons">
            <Button
              buttonStyle={ButtonStyle.Secondary}
              onClick={(): void => setShowTerms(false)}
              buttonSize={ButtonSize.Large}
            >
              Close
            </Button>
            <Button
              onClick={(): void => {
                setShowTerms(false)
                changePurchaser({ agreedToTerms: true })
              }}
              customColors={buttonColors}
              buttonSize={ButtonSize.Large}
            >
              Agree
            </Button>
          </InputGroup>
        </ModalContent>
      </Modal>
    </>
  )
}
