import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FiEdit } from 'react-icons/fi'
import { IoIosArrowUp } from 'react-icons/io'
import {
  Buttonlink,
  colors,
  formatCurrency,
  OrganisationFormat,
  shortVoucherWord,
  stripEmptyFromArray,
  getRemaining,
  getMaxQuantity,
  isTESO,
  scrollToTop,
} from '@one-tree/library'
import { useHistory, useLocation } from 'react-router-dom'
import { useOrganisation } from '../../context/OrganisationProvider'
import { IBasketItem } from '../../types/dataTypes'
import { DeliveryMethod } from '../../types/itemTypes'
import { getUniqueId, getItemValue } from '../../helpers/basketHelper'
import DeleteModal from '../page/DeleteModal'
import { useManualOrder } from '../../context/ManualOrderProvider'
import { useBasket } from '../../context/BasketProvider'
import { useError } from '../../context/ErrorProvider'
import { RoutePath } from '../../types/routes'
import CurrencyDropdown from './controls/Dropdown'
import Incrementor from './controls/Incrementor'
import ItemInfo from './elements/ItemInfo'
import ItemName from './elements/ItemName'
import { usePurchaser } from '../../context/PurchaserProvider'
import { hasQuestions } from '../../pages/details/questions/questionHelper'

const Styles = styled.div`
  ${(props): string => {
    const { theme } = props
    const { linkColor, index } = theme
    // if first in basket (index === 0) or item displayed on its own, show top border
    return !index ? `border-top: 1px solid ${linkColor}};` : ''
  }};
  border-bottom: 1px solid ${(props): string => props.theme.linkColor};
  opacity: 0.3;
  ${(props): string => {
    const {
      theme: { isOpen, noneAreOpen },
    } = props
    return isOpen || noneAreOpen ? 'opacity: 1;' : ''
  }};
`
const Summary = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  padding: 10px 0;
  cursor: pointer;
  user-select: none;
`
const Edit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props): string => props.theme};
  font-size: 0.8rem;
  width: 20px;
  svg {
    width: 16px;
    height: auto;
  }
  margin-left: 15px;
`
const Price = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
`
const Postage = styled.div`
  color: ${colors.darkerGray};
  white-space: nowrap;
`
const Drawer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  hr {
    border-width: 0;
    border-top: 1px dashed ${colors.gray};
    margin: 0;
  }
  &.show-drawer {
    height: auto;
    max-height: 162px;
    transition: max-height 0.2s ease-in;
    &.drawer-ready {
      overflow: visible;
    }
  }
  display: flex;
  flex-direction: column;
`
const Settings = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 10px 0;
`
const ButtonGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  justify-content: space-between;

  margin: auto 0 15px;
  & :only-child {
    margin-left: auto;
  }
  & :last-child {
    text-align: end;
  }
`
const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
`

interface IBasketItemProps {
  basketItem: IBasketItem
  index?: number
  openIndex: number | null
  setIsOpen: (open: boolean) => void
}
export default function BasketItem(props: IBasketItemProps): ReactElement {
  const {
    basketItem, index, openIndex, setIsOpen,
  } = props
  const {
    id, giftItem, postage, recipient, quantity, individuals, format,
  } = basketItem
  const { maxIndividuals, quantitySold, maxPerOrder } = giftItem

  const { purchaser } = usePurchaser()

  const remaining = getRemaining(giftItem.quantity, quantitySold)

  const { linkColor, currencyCode } = useOrganisation()
  const { manualOrderToken } = useManualOrder()
  const { addError, setShowErrors } = useError()
  const history = useHistory()
  const { pathname } = useLocation()
  const {
    basketItems,
    addBasketItem,
    changeItemValue,
    incrementBasketItem,
    changeCurrentItem,
  } = useBasket()

  const voucherWord = shortVoucherWord(basketItem.format)

  const isPost = recipient.deliveryMethod === DeliveryMethod.Post
  const postCost = formatCurrency(postage.cost, currencyCode)

  const [drawerReady, setDrawerReady] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  useEffect(() => {
    scrollToTop()
  }, [deleteModalOpen])

  const isGiftVoucher = format === OrganisationFormat.GiftVouchers

  const duplicate = (): void => {
    if (basketItems.length >= 8) {
      setShowErrors(true)
      addError(
        'Maximum order size reached, please checkout your existing basket first',
      )
    } else {
      const newItem = { ...basketItem, id: getUniqueId() }
      addBasketItem(newItem)
    }
  }

  const hasMultipleValues = giftItem.value?.includes(',')

  const isOpen = openIndex === index
  const noneAreOpen = openIndex === null

  useEffect(() => {
    if (isOpen) {
      // if the drawer is open, wait for the animation to finish before allowing overflow, to
      // accommodate dropdown menus
      setTimeout(() => setDrawerReady(true), 200)
    } else {
      setDrawerReady(false)
    }
  }, [isOpen])
  const drawerClasses = `${isOpen ? 'show-drawer' : ''} ${drawerReady ? 'drawer-ready' : ''}`

  const maxAllowed = getMaxQuantity(remaining, maxPerOrder)

  const value = getItemValue(basketItem, false)

  const showPersonalise = isGiftVoucher && pathname === RoutePath.Basket

  const personaliseButton = showPersonalise ? (
    <Buttonlink
      customColor={linkColor}
      onClick={(): void => {
        changeCurrentItem(basketItem.id)
        history.push(RoutePath.Personalise)
      }}
    >
      Edit personalisation
    </Buttonlink>
  ) : null

  const freeText = value === 0 ? 'Free' : false
  const valueText = freeText || (value && formatCurrency(value, currencyCode))

  const hideQuantity = hasQuestions([basketItem]) && pathname !== RoutePath.Personalise
  const isItemTESO = isTESO(giftItem)

  return (
    <>
      <Styles
        theme={{
          linkColor,
          index,
          isOpen,
          noneAreOpen,
        }}
      >
        <Summary onClick={(): void => setIsOpen(!isOpen)}>
          <ItemName item={basketItem} />
          <Price>
            <div>{valueText}</div>
            {isPost && <Postage>{`+${postCost}`}</Postage>}
          </Price>
          <Edit theme={linkColor}>
            {isOpen ? (
              <IoIosArrowUp style={{ width: '22px' }} />
            ) : (
              <>
                <FiEdit />
                edit
              </>
            )}
          </Edit>
        </Summary>
        <Drawer className={drawerClasses}>
          <hr />
          <Settings>
            <ItemInfo item={basketItem} purchaser={purchaser} />
            <Controls>
              {giftItem.value && hasMultipleValues && (
                <CurrencyDropdown
                  label="Price:"
                  options={stripEmptyFromArray(giftItem.value.split(','))}
                  selectedValue={value}
                  selectValue={(newValue): void => {
                    changeItemValue(id, newValue)
                  }}
                  currencyCode={currencyCode}
                />
              )}
              {isItemTESO && !hideQuantity && (
                <Incrementor
                  label="Quantity:"
                  count={quantity}
                  adjust={(amount): void => {
                    incrementBasketItem(id, 'quantity', amount)
                  }}
                  max={maxAllowed}
                />
              )}
              {maxIndividuals && (
                <Incrementor
                  label="People:"
                  count={individuals}
                  adjust={(amount): void => {
                    incrementBasketItem(id, 'individuals', amount)
                  }}
                  max={maxIndividuals}
                />
              )}
            </Controls>
          </Settings>
          <ButtonGroup>
            {personaliseButton}
            {manualOrderToken && isGiftVoucher && (
              <Buttonlink customColor={linkColor} onClick={duplicate}>
                Duplicate
              </Buttonlink>
            )}
            <Buttonlink
              customColor={linkColor}
              onClick={(): void => setDeleteModalOpen(true)}
            >
              {`Delete this ${voucherWord}`}
            </Buttonlink>
          </ButtonGroup>
        </Drawer>
      </Styles>
      <DeleteModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        itemId={id}
        onDelete={(): void => setIsOpen(false)}
      />
    </>
  )
}
