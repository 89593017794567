import React, {
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useState,
  createContext,
} from 'react'
// eslint-disable-next-line
import jwt_decode from 'jwt-decode'
import { refreshManualOrderToken } from '../helpers/APIHelper'
import { useError } from './ErrorProvider'
import { clearSessions, getSession } from '../helpers/sessionHelper'

export interface IDecodedToken {
  role: 'manual-order' | undefined
  id: string | undefined
}
export interface ITokenResponse extends IDecodedToken {
  token: string
}
interface IManualOrderState {
  manualOrderToken: string | null
  setManualOrderToken: (token: string | null) => void
  getManualOrderToken: (token: string) => Promise<ITokenResponse | false>
}
const ManualOrderContext = createContext<IManualOrderState | undefined>(
  undefined,
)

function ManualOrderProvider({
  children,
}: {
  children: ReactNode
}): ReactElement {
  const init = getSession('manualOrderToken')
  const [manualOrderToken, setManualOrderToken] = useState<string | null>(init)

  const { addError, setShowErrors } = useError()

  const getManualOrderToken = async (
    initToken: string,
  ): Promise<ITokenResponse | false> => {
    clearSessions()

    const res = await refreshManualOrderToken(initToken)
    if (res.error) {
      addError(res.error)
      setShowErrors(true)
      return false
    }
    if (!res.data) {
      addError('No response from server')
      setShowErrors(true)
      return false
    }
    const { token } = res.data
    const { role, id }: IDecodedToken = jwt_decode(token)

    return { token, role, id }
  }

  const value = useMemo(
    () => ({
      manualOrderToken,
      setManualOrderToken,
      getManualOrderToken,
    }),
    [manualOrderToken],
  )

  return (
    <ManualOrderContext.Provider value={value}>
      {children}
    </ManualOrderContext.Provider>
  )
}

function useManualOrder(): IManualOrderState {
  const context = useContext(ManualOrderContext)
  if (context === undefined) {
    throw new Error('useManualOrder must be used within a ManualOrderProvider')
  }
  return context
}

export { ManualOrderProvider, useManualOrder }
