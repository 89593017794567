import {
  CurrencyCode,
  ICategoryResponse,
  IGiftItemResponse,
  OrganisationFormat,
  Font,
  FontSize,
} from '@one-tree/library'

export enum PaymentProvider {
  Stripe = 'stripe',
  PayPal = 'paypal',
  Trust = 'securetrading',
}

export interface IOrganisationResponse {
  id: number
  format: OrganisationFormat
  purchaserTerms: string
  termsAndConditions: string
  paymentProvider: PaymentProvider
  currency: CurrencyCode
  postage: Postage

  emailDelivery: boolean
  postDelivery: boolean

  introductoryText?: string
  font?: Font

  colourBodyText?: string
  colourHeaderText?: string
  colourHighlight?: string
  colourPageBackground?: string
  colourPriceText?: string
  colourTextOnBackground?: string
  colourTextOnHighlight?: string
  colourHeaderBackground?: string
  textOnBackgroundSize?: FontSize

  items: IGiftItemResponse[]
  categories?: ICategoryResponse[]
  categoryImages?: boolean

  paymentKey?: string
  gaTrackingId?: string
  fbPixelId?: string
}

export type Postage = {
  cost: number
  description: string
  message: string
  name: string
}
