export const getSession = (key: string): string | null => {
  try {
    return sessionStorage.getItem(key)
  } catch {
    return null
  }
}

export const setSession = (key: string, value: string): void | null => {
  try {
    return sessionStorage.setItem(key, value)
  } catch {
    return null
  }
}

export const removeSession = (key: string): void | null => {
  try {
    sessionStorage.removeItem(key)
    return null
  } catch {
    return null
  }
}

export const clearSessions = (): void | null => {
  try {
    sessionStorage.clear()
    return null
  } catch {
    return null
  }
}

export const getSessionNumber = (key: string): number | null => {
  try {
    const item = sessionStorage.getItem(key)
    if (item && (Number(item) || Number(item) === 0)) return Number(item)
    return null
  } catch {
    return null
  }
}
