import {
  Button,
  ButtonStyle,
  colors,
  getFont,
  Modal,
  scrollToTop,
  theme,
  useFirstRender,
} from '@one-tree/library'
import React, {
  ReactElement, ReactNode, useEffect, useState,
} from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import Footer from '../Footer'
import { useOrganisation } from '../../context/OrganisationProvider'
import Progress from '../header/Progress'
import { useBasket } from '../../context/BasketProvider'
import { prepareOrder } from '../../helpers/basketHelper'
import { usePurchaser } from '../../context/PurchaserProvider'
import { validateOrder, ValidationError } from '../../helpers/APIHelper'
import { RoutePath } from '../../types/routes'

const GlobalStyle = createGlobalStyle`
  body {
    ${theme.scrollbar};
    overflow: overlay;
  }
`
const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background-color: ${colors.white};
  overflow-x: hidden;
  font-family: ${(props): string => props.theme};
`
const PageStyles = styled.div`
  margin: 0 10px;
  width: 454px;
  display: grid;
  row-gap: 15px;
`
const ModalContent = styled.div`
  max-width: 400px;
`
const ModalTitle = styled.h3`
  color: ${colors.red};
  margin: 0 0 10px;
`
const ModalItem = styled.div`
  font-size: 0.9rem;
  margin: 5px 0;
`
function Page(props: { children?: ReactNode }): ReactElement {
  const { children } = props
  const { organisation } = useOrganisation()
  const location = useLocation()
  const { currentItem, basketItems, removeBasketItem } = useBasket()
  const { purchaser } = usePurchaser()
  const [errors, setErrors] = useState<ValidationError[]>([])
  const history = useHistory()

  useEffect(() => {
    scrollToTop()
  }, [location, currentItem?.id])

  const validate = async (): Promise<void> => {
    if (!organisation || basketItems.length === 0) return
    const data = prepareOrder(basketItems, purchaser, organisation.id)
    if (!data) return

    const res = await validateOrder(data)

    // if there are errors with the basket
    if (res instanceof Array && res.find(({ id }: ValidationError) => id)) {
      setErrors(res)
    }
  }
  const firstRender = useFirstRender()
  useEffect(() => {
    // TODO remove once API is ready
    const shouldValidate = false
    if (firstRender && shouldValidate) validate()
  }, [])

  const deleteInvalid = (): void => {
    setErrors((prevState) => {
      const itemsToDelete = basketItems.filter(({ giftItem }) => {
        const isError = prevState.some(({ id }) => giftItem.id === id)
        return isError
      })
      itemsToDelete.forEach(({ id }) => removeBasketItem(id))

      if (location.pathname === RoutePath.Payment) {
        // if on payment, go back to basket to avoid payment errors
        history.push(RoutePath.Basket)
      }

      return []
    })
  }

  return (
    <Container theme={getFont(organisation?.font)}>
      <GlobalStyle />
      <PageStyles>
        <Progress />
        {children}
        {organisation && (
          <Footer paymentProvider={organisation.paymentProvider} />
        )}
      </PageStyles>
      <Modal
        modalOpen={errors.length > 0}
        onModalClose={deleteInvalid}
        modalAction={deleteInvalid}
      >
        <ModalContent>
          <ModalTitle>
            Some items in your order are no longer available and have been
            removed:
          </ModalTitle>
          {errors.map(({ id, description }) => {
            const name = basketItems.find((item) => item.giftItem.id === id)
              ?.giftItem.name

            return (
              <ModalItem key={id}>
                <strong>{name}</strong>
                <div>{`Reason: ${description}`}</div>
              </ModalItem>
            )
          })}
          <Button
            onClick={deleteInvalid}
            style={{ marginTop: '10px' }}
            buttonStyle={ButtonStyle.Secondary}
          >
            Ok
          </Button>
        </ModalContent>
      </Modal>
    </Container>
  )
}
export default Page
