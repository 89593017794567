import {
  AsyncButton,
  Button,
  ButtonSize,
  ButtonStyle,
  capitalize,
  colors,
  Modal,
  ModalPosition,
  OrganisationFormat,
  scrollToTop,
  shortVoucherWord,
  useFirstRender,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { RoutePath } from '../../types/routes'
import Page from '../../components/page/Page'
import PageNavigation from '../../components/page/PageNavigation'
import { useBasket } from '../../context/BasketProvider'
import { useOrganisation } from '../../context/OrganisationProvider'
import { useManualOrder } from '../../context/ManualOrderProvider'
import { useError } from '../../context/ErrorProvider'
import Personalisation from './Personalisation'
import { previewPurchase } from '../../helpers/APIHelper'
import { prepareBasketItem } from '../../helpers/basketHelper'
import { PageTitle } from '../../components/page/PageElements'
import Recipient from './delivery/Recipient'
import Method from './delivery/Method'
import {
  getItemErrors,
  getPersonaliseErrors,
} from './helpers/recipientValidity'
import {
  getInvalidItems,
  isBasketValid,
} from '../../helpers/basketValidityHelper'
import { DeliveryMethod, RecipientType } from '../../types/itemTypes'
import EmailForm from './delivery/forms/EmailForm'
import PostForm from './delivery/forms/PostForm'
import Basket from '../../components/basket/Basket'
import PostInfo from '../../components/page/PostInfo'
import {
  getQuestionResponseErrors,
  hasQuestions,
} from '../details/questions/questionHelper'
import Questions from '../details/questions/Questions'

const ModalContent = styled.div`
  padding: 0 10px 10px;
  display: flex;
  flex-direction: column;
  h3 {
    margin: 10px 0 5px;
    align-self: flex-start;
  }
  img {
    border: 1px solid ${colors.darkGray};
    width: 100%;
  }
  button {
    margin-top: 10px;
    align-self: flex-end;
  }
`

export default function Personalise(): ReactElement | null {
  const {
    currentItem, currentRecipient, basketInvalid, basketItems,
  } = useBasket()

  const { manualOrderToken } = useManualOrder()

  const { buttonColors, organisation } = useOrganisation()
  const {
    changeCurrentItem,
    removeBasketItem,
    changeCurrentRecipient,
    answerQuestions,
  } = useBasket()

  const history = useHistory()
  const location = useLocation<{ from?: RoutePath }>()

  const {
    showErrors, setShowErrors, addError, clearErrors,
  } = useError()
  const firstRender = useFirstRender()

  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (firstRender) setShowErrors(false)
    clearErrors()
    if (!currentItem) changeCurrentItem()
    setLoaded(true)
  }, [currentItem])

  const errors = [
    ...getItemErrors(currentItem),
    ...getQuestionResponseErrors(currentItem),
  ]
  const notValid = errors.length > 0

  const personaliseErrors = getPersonaliseErrors(currentItem)
  const personalisationValid = personaliseErrors.length > 0

  const [previewImage, setPreviewImage] = useState<string | false>(false)
  useEffect(() => {
    if (previewImage) scrollToTop()
  }, [previewImage])

  const handlePreview = async (): Promise<void> => {
    if (!currentItem) {
      setShowErrors(true)
      addError('No item selected')
      return
    }
    if (personaliseErrors && personaliseErrors.length) {
      personaliseErrors.forEach((error) => addError(error))
      setShowErrors(true)
      return
    }

    const item = prepareBasketItem(currentItem)
    const res = await previewPurchase(item)

    if (res.error) {
      setShowErrors(true)
      addError(res.error)
      return
    }
    if (!res.data) {
      setShowErrors(true)
      addError('No response from server')
      return
    }

    setPreviewImage(res.data.image)
  }

  const voucherWord = shortVoucherWord(currentItem?.format)

  const isTicket = currentItem?.format === OrganisationFormat.Tickets

  const onForward = (): boolean => {
    if (hasQuestions(basketItems)) {
      answerQuestions()
    }
    if (basketInvalid && !manualOrderToken) {
      changeCurrentItem()
      return false
    }
    return true
  }

  const getForwardLabel = (): string => {
    if (!isTicket && manualOrderToken) return 'Back to basket'
    const invalid = getInvalidItems(basketItems).length
    const onLastInvalid = invalid === 1 && notValid

    if (!invalid || basketItems.length === 1 || onLastInvalid || isTicket) {
      return 'Continue'
    }
    return `Edit next ${shortVoucherWord(currentItem?.format)}`
  }

  const { deliveryMethod, recipientType } = currentRecipient
  const isRecipient = recipientType === RecipientType.Recipient
  const isEmail = deliveryMethod === DeliveryMethod.Email
  const isPost = deliveryMethod === DeliveryMethod.Post
  const isPrint = deliveryMethod === DeliveryMethod.Print

  const isFromHome = location.state?.from === RoutePath.Home
  const backLabel = isFromHome
    ? `Cancel this ${shortVoucherWord(organisation?.format)}`
    : ''

  const back = (): void => {
    if (isFromHome && currentItem) {
      removeBasketItem(currentItem.id)
    }
    const restOfBasket = basketItems.filter(
      (item) => item.id !== currentItem?.id,
    )
    if (basketItems.length > 0 && isBasketValid(restOfBasket)) {
      history.push(RoutePath.Basket)
    } else {
      history.goBack()
    }
  }

  useEffect(() => {
    if (isPrint) {
      changeCurrentRecipient({ recipientType: undefined })
    }
  }, [isPrint])

  const renderPersonalisation = (
    <>
      <Personalisation showErrors={showErrors} />
      <AsyncButton
        buttonStyle={ButtonStyle.Secondary}
        buttonSize={ButtonSize.Large}
        customColors={personalisationValid ? undefined : buttonColors}
        onClick={handlePreview}
      >
        {`Preview ${voucherWord}`}
      </AsyncButton>
      <PageTitle>Delivery</PageTitle>
      <Method
        showErrors={showErrors}
        setShowErrors={setShowErrors}
        auth={Boolean(manualOrderToken)}
      />
      {isPost && <PostInfo item={currentItem} />}
      {!isPrint && (
        <Recipient
          showErrors={showErrors}
          auth={Boolean(manualOrderToken)}
          setShowErrors={setShowErrors}
        />
      )}
      {isRecipient && isEmail && <EmailForm showErrors={showErrors} />}
      {isRecipient && isPost && <PostForm showErrors={showErrors} />}
    </>
  )

  return loaded ? (
    <Page>
      {isTicket && <Questions />}
      {!isTicket && currentItem && (
        <>
          <Basket items={[currentItem]} />
          {renderPersonalisation}
        </>
      )}
      <PageNavigation
        backLabel={manualOrderToken && !isTicket ? undefined : backLabel}
        onBack={manualOrderToken && !isTicket ? undefined : back}
        forward={RoutePath.Basket}
        forwardLabel={getForwardLabel()}
        onForward={onForward}
        forwardDisabled={notValid}
        errors={errors}
      />
      {previewImage && (
        <Modal
          modalOpen={Boolean(previewImage)}
          onModalClose={(): void => setPreviewImage(false)}
          modalAction={(): void => setPreviewImage(false)}
          position={ModalPosition.Top}
        >
          <ModalContent>
            <h2>{`${capitalize(voucherWord)} preview`}</h2>
            <img src={previewImage} alt={`Preview ${voucherWord}`} />
            <Button
              className={ButtonStyle.Secondary}
              type="button"
              onClick={(): void => setPreviewImage(false)}
              buttonSize={ButtonSize.Large}
            >
              Close
            </Button>
          </ModalContent>
        </Modal>
      )}
    </Page>
  ) : null
}
